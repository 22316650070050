import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

// Slice
import { digLogAction } from 'src/features/digLog/slice';
import { combineServiceActions } from 'src/features/superAdmin/combineService/slice';

// component
import Search from 'src/components/common/search';
import Filter from 'src/components/common/filter/Filter';

// scss
import styles from './styles.module.scss';

// icons
import iconClose from 'src/assets/icons/ic_close.svg';

// img
import InputField from 'src/components/inputField';
import imgCleanWindow from 'src/assets/images/img_logo_clean_window.png';

// Helper
import { searchTextByName } from 'src/utils/help';

const TableListCombine = (props) => {
  const {
    listServiceNotCombine,
    optionsServices,
    handleChangeFilterByService,
    isSearch,
    setIsSearch,
    active,
    setActive,
    isResetSearch,
    setIsResetSearch,
  } = props;

  // Utils
  const cx = classNames.bind(styles);
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm();

  const [selectedServiceFilterOption, setSelectedServiceFilterOption] = useState(Object.keys(optionsServices)[0]);

  const limitService = active.filter((ele) => ele === true).length;

  useEffect(() => {
    setActive(listServiceNotCombine.map(() => false));
    setIsSearch(listServiceNotCombine.map(() => true));
  }, [listServiceNotCombine]);

  // Handle Logic
  /**
   * Handle event when click add service to combine
   * Only select 2 service
   * @param {*} index is index of service not combine
   */
  const handlePushActive = (index) => {
    if (limitService === 2) {
      dispatch(digLogAction.getTitle({ title: 'Only two services are allowed.' }));
      dispatch(digLogAction.showDigLog());
    } else {
      // Set active service
      active[index] = !active[index];
      setActive([...active]);
    }
  };

  /**
   * Handle event when unClick service from box combine
   * @param {*} index
   */
  const handleDescActive = (index) => {
    // Set active service
    active[index] = !active[index];
    setActive([...active]);
  };

  const handleSearch = (textSearch) => {
    const dataSearch = searchTextByName(listServiceNotCombine, textSearch);
    listServiceNotCombine.forEach((ele, idx) => {
      isSearch[idx] = dataSearch.includes(ele) ? true : false;
    });
    setIsSearch([...isSearch]);
  };

  const handleCombine = (data) => {
    const listIdCombineService = listServiceNotCombine
      .filter((ele, idx) => {
        return active[idx];
      })
      .map((ele) => ele._id);

    const offer = parseFloat(data?.offer);

    if (!offer || offer > 100 || offer < 1) {
      dispatch(digLogAction.getTitle({ title: 'Offer must be between 1 and 100' }));
      return false;
    }

    const payload = {
      body: {
        services: listIdCombineService,
        offer: offer,
      },
    };

    if (limitService === 2) {
      dispatch(combineServiceActions.createCombineService(payload));
    }
  };

  return (
    <div id='tableListCombine' className={cx('tbWrapCombine')}>
      <div className={cx('tbCombine')}>
        <div className={cx('tbContent')}>
          <div className={cx('tbSearch')}>
            <Search className={cx('tbSearchValue')} handleSearch={handleSearch} />
          </div>
          <Filter
            filter={optionsServices}
            selected={selectedServiceFilterOption}
            setSelected={setSelectedServiceFilterOption}
            handleChangeFilter={handleChangeFilterByService}
            classCombine={true}
          />
          <div className={cx('tbItemCombine')}>
            {listServiceNotCombine.map((service, idx) =>
              !active[idx] && isSearch[idx] ? (
                <div className={cx('tbWrapElement')} key={idx} onClick={() => handlePushActive(idx)}>
                  <img
                    src={listServiceNotCombine[idx].logo || imgCleanWindow}
                    onError={(err) => {
                      err.target.src = imgCleanWindow;
                    }}
                    alt='imgAvatar'
                    className={cx('tbAvatar')}
                  />
                  <span className={cx('tbValueCombine')}>{service.name}</span>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>

      <div className={cx('tbCombine')}>
        <div className={cx('tbContent')}>
          <div className={cx('tbWrapForm')}>
            <form className={cx('tbInput')} onSubmit={handleSubmit(handleCombine)}>
              <InputField
                classNameInput={cx('crValueInput')}
                type={'number'}
                name={'offer'}
                min={1}
                max={100}
                register={register}
                onChange={(e) => setValue('offer', e.target.value)}
                // valueAsNumber={false}
                placeholder={'Enter offer (%)'}
              />
              <button
                type='submit'
                className={limitService === 2 ? cx('tbBtnCombineSuccess') : cx('tbBtnCombine')}
                disabled={limitService === 2 ? false : true}
              >
                Combine
              </button>
            </form>
          </div>
          <div className={cx('tbItemCombine')}>
            {listServiceNotCombine.map((service, idx) =>
              active[idx] ? (
                <div className={cx('tbWrapElementOffer')} key={idx}>
                  <div className={cx('tbWrapperContent')}>
                    <img
                      src={service.logo || imgCleanWindow}
                      onError={(err) => {
                        err.target.src = imgCleanWindow;
                      }}
                      alt='imgAvatar'
                      className={cx('tbAvatar')}
                    />
                    <span className={cx('tbValueCombine')}>{service.name}</span>
                  </div>
                  <div className={cx('tbBtnClose')} onClick={() => handleDescActive(idx)}>
                    <img src={iconClose} alt='iconClose' className={cx('tbBtnCloseIcon')} />
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableListCombine;
