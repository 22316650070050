//Pages
// import Home from 'src/pages/home';
import ListService from 'src/pages/superAdmin/service/list/List';
import Service from 'src/pages/superAdmin/service';
import CreateService from 'src/pages/superAdmin/service/create';
import CombineService from 'src/pages/superAdmin/combineService';
import ListCombineService from 'src/pages/superAdmin/combineService/list';
import Login from 'src/pages/superAdmin/login';
import ForgotPassword from 'src/pages/superAdmin/forgotPassword';
import { routerPaths } from 'src/utils/routers';
import HouseHolds from 'src/pages/superAdmin/household';
import CreateHouseHold from 'src/pages/superAdmin/household/create';
import ListHouse from 'src/pages/superAdmin/household/list/listhousehold/ListHouseHold';
import ListRequests from 'src/pages/superAdmin/household/list/listrequests/ListRequests';
import CheckMail from 'src/pages/superAdmin/checkMail';
import CreateNewPassword from 'src/pages/superAdmin/createNewPassword';
import PageNotFound from 'src/layouts/components/pageNotFound/PageNotFound';
import UserManagement from 'src/pages/superAdmin/userManagement';
import ListUserManagement from 'src/pages/superAdmin/userManagement/listUserManagement';
import UpdateCombineService from 'src/pages/superAdmin/combineService/create';
import ListTow from 'src/pages/superAdmin/service/listTow/ListTow';
import ListSubService from 'src/pages/superAdmin/subService/list/List';
import CreateSubService from 'src/pages/superAdmin/subService/create';
import ListTowSub from 'src/pages/superAdmin/subService/listTow/ListTow';
import SubService from 'src/pages/superAdmin/subService';
import Orders from 'src/pages/superAdmin/orders';

export const authRoutes = [
  {
    // path: `${routerPaths.LOGIN}`,
    index: true,
    component: Login,
  },
  {
    path: `${routerPaths.FORGOT_PASSWORD}`,
    component: ForgotPassword,
  },
  {
    path: `${routerPaths.CHECK_MAIL}`,
    component: CheckMail,
  },
  {
    path: `${routerPaths.CREATE_NEW_PASSWORD}`,
    component: CreateNewPassword,
  },
];

export const superAdminRoutes = [
  {
    path: `${routerPaths.SERVICES}`,
    component: Service,
    children: [
      { path: routerPaths.LIST, component: ListService, index: true },
      { path: routerPaths.CREATE, component: CreateService },
      { path: routerPaths.LIST_TOW, component: ListTow },
    ],
  },
  {
    path: `${routerPaths.SUB_SERVICES}`,
    component: SubService,
    children: [
      { path: routerPaths.LIST, component: ListSubService, index: true },
      { path: routerPaths.CREATE, component: CreateSubService },
      { path: routerPaths.LIST_TOW, component: ListTowSub },
    ],
  },
  {
    path: `${routerPaths.HOUSEHOLDS}`,
    component: HouseHolds,
    children: [
      { path: routerPaths.LIST_HOUSE, component: ListHouse, index: true },
      { path: routerPaths.CREATE, component: CreateHouseHold },
    ],
  },
  {
    path: `${routerPaths.ORDERS}`,
    component: Orders,
    children: [{ path: routerPaths.LIST_ORDERS, component: ListRequests, index: true }],
  },
  {
    path: `${routerPaths.COMBINE_SERVICES}`,
    component: CombineService,
    children: [
      { path: routerPaths.LIST, component: ListCombineService, index: true },
      { path: routerPaths.CREATE, component: UpdateCombineService },
    ],
  },
  {
    path: `${routerPaths.USERS}`,
    component: UserManagement,
    children: [{ path: routerPaths.LIST, component: ListUserManagement, index: true }],
  },
  {
    path: `*`,
    layout: PageNotFound,
  },
];

export const adminRoutes = [
  {
    path: `${routerPaths.HOUSEHOLDS}`,
    component: HouseHolds,
    children: [
      { path: routerPaths.LIST_HOUSE, component: ListHouse, index: true },
      { path: routerPaths.CREATE, component: CreateHouseHold },
    ],
  },
  {
    path: `${routerPaths.ORDERS}`,
    component: Orders,
    children: [{ path: routerPaths.LIST_ORDERS, component: ListRequests, index: true }],
  },
  {
    path: `*`,
    layout: PageNotFound,
  },
];
