import axiosClient from '../axiosClient';
import { householdUrl } from 'src/utils/constants';

const householdApi = {
  getAllHousehold(params) {
    const url = `${householdUrl.getAllHouseHold}`;
    return axiosClient.get(url, { params });
  },

  createHouseHold(params) {
    const url = `${householdUrl.getAllHouseHold}`;
    return axiosClient.post(url, params);
  },

  deleteHousehold(params) {
    const url = `${householdUrl.getAllHouseHold}/${params._id}`;
    return axiosClient.delete(url, params);
  },

  updateHousehold(params) {
    const url = `${householdUrl.getAllHouseHold}/${params._id}`;
    return axiosClient.put(url, params);
  },
};

export default householdApi;
