import classNames from 'classnames/bind';
import { useState, useEffect } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
// import component
import Search from 'src/components/common/search';
import Filter from 'src/components/common/filter/Filter';
import CreateService from '../create';

// import component common
import TableHeader from 'src/components/common/table/tableHeaderSercive/TableHeader';
import TableItem from 'src/components/common/table/tableItem/tableItem';
import TableHeaderMob from 'src/components/common/table/tablemob/Tablemode';
import PagiNation from 'src/components/common/pagination/Pagination';
import NoDataAvailable from 'src/components/common/noDataAvailable/NoDataAvaliable';
//
import {
  serviceActions,
  selectServiceList,
  selectServiceTotal,
  selectServiceTypeAction,
  selectServiceShowMessage,
  selectServiceFilter,
  selectServiceShowAdd,
  selectServicePaginationTotal,
  selectServiceMessage,
  selectServiceFetchDataAfterAction,
} from 'src/features/superAdmin/service/slice';
import { digLogAction } from 'src/features/digLog/slice';

//utils
import { serviceFilter } from 'src/utils/enum';
import { contentDialog } from 'src/utils/contentDialog';

//scss
import styles from './styles.module.scss';
import { convertArrToObjectOptions, handleErrorMessages } from 'src/utils/help';
const cx = classNames.bind(styles);

const ListService = () => {
  const dispatch = useDispatch();

  const [showSidebar, setShowSidebar] = useState(false);

  /**
   * UseSelector Block
   */
  const listService = useSelector(selectServiceList);
  const total = useSelector(selectServiceTotal);
  const isShowMessage = useSelector(selectServiceShowMessage);
  const typeAction = useSelector(selectServiceTypeAction);
  const filter = useSelector(selectServiceFilter);
  const addShow = useSelector(selectServiceShowAdd);
  const paginationTotal = useSelector(selectServicePaginationTotal);
  const message = useSelector(selectServiceMessage);

  const handleShow = () => {
    setShowSidebar(true);
  };

  const handleSearch = (values) => {
    dispatch(serviceActions.filter({ ...filter, search: values, page: 1 }));
  };

  const handlePageChange = (values) => {
    dispatch(serviceActions.filter(values));
  };

  /**
   * UseEffect Block
   */

  useEffect(() => {
    dispatch(serviceActions.resetState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(serviceActions.getAllService(filter));
  }, [dispatch, filter]);

  // Handle show modal
  useEffect(() => {
    if (isShowMessage === true && typeAction === 'CREATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.CREATE_NEW_SERVICE.SUCCESS,
          function: () => {
            dispatch(serviceActions.resetStatus());
            dispatch(serviceActions.getAllService(filter));
          },
        })
      );
    }
    if (isShowMessage === true && typeAction === 'DELETE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.DELETE_SERVICE.SUCCESS,
          function: () => {
            dispatch(serviceActions.resetStatus());
            dispatch(serviceActions.getAllService(filter));
          },
        })
      );
    }
    if (isShowMessage === true && typeAction === 'UPDATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.UPDATE_SERVICE.SUCCESS,
          function: () => {
            dispatch(serviceActions.resetStatus());
            dispatch(serviceActions.getAllService(filter));
          },
        })
      );
    }
    if (isShowMessage === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(serviceActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, message, isShowMessage, typeAction]);

  useEffect(() => {
    if (addShow === true) {
      setShowSidebar(false);
      dispatch(serviceActions.cloneAdd());
    }
  }, [addShow, dispatch]);

  return (
    <>
      <div className={cx('lService')}>
        <p className={cx('lServiceName')}>Services: {total > 0 ? total : 0}</p>
        <button className={cx('lBtnAddService')} onClick={handleShow}>
          <p>+</p>
          <div>Add New Service</div>
        </button>
      </div>

      <div className={cx('lBlock')}>
        <div className={cx('lFilters')}>
          <div className={cx('lFilters')}></div>
          <Search handleSearch={handleSearch} placeholder={'Search by Service'} />
        </div>
        <div className={cx('lItemDesktop')}>
          <TableHeader />
          <div className={cx('lItemWrapper')}>
            {paginationTotal ? (
              listService.map((value, i) => <TableItem key={i} {...value} delete={true} />)
            ) : (
              <NoDataAvailable />
            )}
          </div>
        </div>
        <div>
          {paginationTotal > 0 ? (
            <div className={cx('lItemMob')}>
              {listService.map((value, i) => (
                <TableHeaderMob key={i} {...value} />
              ))}
            </div>
          ) : (
            <NoDataAvailable />
          )}
        </div>
        <div className={cx('lIPagination')}>
          {paginationTotal > 0 ? (
            <PagiNation filter={filter} total={paginationTotal} handlePageChange={handlePageChange} />
          ) : null}
        </div>
      </div>

      <CreateService showService={showSidebar} setShowSidebar={setShowSidebar} />
    </>
  );
};

export default ListService;
