//Library
import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
//Components
//Feature
import { userManagementAction } from 'src/features/superAdmin/userManagement/slice';
import { digLogAction } from 'src/features/digLog/slice';

//Utils
import { capitalizeFirstLetter } from 'src/utils/help';
import { contentDialog } from 'src/utils/contentDialog';
//Icon
import iconEdit from 'src/assets/icons/ic_edit.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';
import iconDeleteBox from 'src/assets/icons/ic_delete_box.svg';
import iconStatus from 'src/assets/icons/ic_check_box.svg';
//Image
import userImage from 'src/assets/images/img_avatar.jpg';
//Scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const TableItemUser = (props) => {
  const dispatch = useDispatch();

  /**
   * props
   */
  const { _id, name, email, gender, phoneNumber, role, isActive, avatar, handleShow, setEdit } = props;

  /**
   * handle
   */
  const deleteUser = () => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE.CLICK,
        function: () => {
          dispatch(userManagementAction.deleteUser(_id));
        },
        functionCancel: () => {},
      })
    );
  };

  const handleDataSet = () => {
    setEdit({
      avatar: avatar,
      name: name?.firstName,
      lastName: name?.lastName,
      gender: capitalizeFirstLetter(gender),
      phoneNumber: {
        countryCode: phoneNumber?.countryCode,
        phone: phoneNumber?.phone,
      },
      role: capitalizeFirstLetter(role),
      status: isActive,
      _id: _id,
    });
    handleShow();
  };

  return (
    <>
      <div className={cx('umTableItem')}>
        <div className={cx('umNameField')}>
          <div className={cx('umUserImg')}>
            {/* <img src={avatar || userImage} className={cx('imgUser')} alt='avatar' /> */}
            <img
              src={avatar || userImage}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = userImage;
              }}
              alt=''
              className={cx('imgUser')}
            />
          </div>
          <p className={cx('umNameValue')}>{email}</p>
        </div>

        {/* <div className={cx('umEmailField')}>{email}</div> */}
        <div>{capitalizeFirstLetter(gender)}</div>
        <div className={cx('umPhoneNumber')}>{phoneNumber?.phone}</div>
        <div className={cx('role')}>{capitalizeFirstLetter(role)}</div>
        <div className={isActive ? cx('status') : cx('notStatus')}>
          {isActive ? (
            <img src={iconStatus} className={cx('icon')} alt='icon' />
          ) : (
            <img src={iconDeleteBox} className={cx('iconBox')} alt='icon' />
          )}
        </div>
        <div className={cx('flex')}>
          <div className={cx('icon-edit')} onClick={handleDataSet}>
            <img src={iconEdit} className={cx('margin')} alt='icon' />
          </div>
          <div className={cx('icon-delete')} onClick={deleteUser}>
            <img src={iconDelete} className={cx('margin')} alt='icon' />
          </div>
        </div>
      </div>
    </>
  );
};

export default TableItemUser;
