import React from 'react';
import classNames from 'classnames/bind';

//scss
import styles from './styles.module.scss';
const cx = classNames.bind(styles);

const TableHeaderHouseHold = () => {
  return (
    <div id='hhHederHouseHold' className={cx('hhHederHouseHold')}>
      <p>Household ID</p>
      <p>Household Name</p>
      <p>Size</p>
      <p>Phone Number</p>
      <div className={cx('status')}>
        <p>Status</p>
      </div>
      <p className={cx('hhActions')}>Actions</p>
    </div>
  );
};

export default TableHeaderHouseHold;
