import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Component
import Dropdown from 'src/components/dropdown';
import { serviceDropdown } from 'src/utils/enum';
import InputField from 'src/components/inputField';

//features
import { serviceActions, selectServiceList, selectServiceListAll } from 'src/features/superAdmin/service/slice';
import { digLogAction } from 'src/features/digLog/slice';
import {
  selectSubServiceFilter,
  selectSubServiceLoading,
  selectSubServiceShowAdd,
  selectSubServiceShowMessage,
  subServiceActions,
} from 'src/features/superAdmin/subService/slice';

//

// scss
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

//
import { contentDialog, errorsResponse } from 'src/utils/contentDialog';

/** image & icon */
import imgAvatar from 'src/assets/images/img_logo_clean_window.png';
import iconUpdate from 'src/assets/icons/ic_edit_logo.svg';
import iconClose from 'src/assets/icons/ic_close.svg';
import { convertArrToObjectOptions, getTitleMessagePrice } from 'src/utils/help';
import TextareaField from 'src/components/textareaField';

const CreateSubService = (props) => {
  const dispatch = useDispatch();
  const cx = classNames.bind(styles);
  const { showSubService, showSidebar, setShowSidebar, listEdit, title } = props;
  console.log(`[CreateSubService] props -> ${JSON.stringify(listEdit, null, 2)}`);

  const listServiceAll = useSelector(selectServiceListAll);
  const addShow = useSelector(selectSubServiceShowAdd);
  const loading = useSelector(selectSubServiceLoading);
  const isShowMessage = useSelector(selectSubServiceShowMessage);
  const [options, setOptions] = useState('ACTIVE');
  const [optionSelected, setOptionSelected] = useState(listServiceAll[0]?._id);
  const [dataForm, setDataForm] = useState();
  const [fileUrl, setFileUrl] = useState(null);
  const [imgFile, setImgFile] = useState('');
  const [show, setShow] = useState();
  const [up, setUp] = useState(false);
  const filter = useSelector(selectSubServiceFilter);
  const [isTypeSubService, setIsTypeSubService] = useState(false);

  const subServiceForm = useRef(null);

  const optionsServices = convertArrToObjectOptions(listServiceAll);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  let formData = new FormData();

  const processImage = (e) => {
    const file = e.target.files[0];

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      if (!listEdit?.logo) {
        dispatch(digLogAction.getTitle({ title: contentDialog.IMAGE.NOT_FILE_IMAGE }));
      }

      return false;
    }

    if (file.size > 2000000) {
      dispatch(digLogAction.getTitle({ title: contentDialog.IMAGE.MAX_IMAGE }));
      return false;
    }

    const imageUrl = URL.createObjectURL(file);

    setFileUrl(imageUrl);
    let fromData = new FormData();
    fromData.append('file', file);
    setImgFile(fromData);
  };

  const handleCloseSidebar = () => {
    setShowSidebar(false);
    setShow(false);
    reset();
    setFileUrl(null);
    setOptions('ACTIVE');
    setIsTypeSubService(false);
    setUp(!up);
    setOptionSelected(listServiceAll[0]?._id);
    if (subServiceForm.current) {
      subServiceForm.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    let formUpdate;
    if (!listEdit?.isOther) {
      formUpdate = {
        inside: listEdit?.inside || '',
        outside: listEdit?.outside || '',
        both: listEdit?.both || '',
        price: '',
      };
    }
    if (listEdit?.isOther) {
      formUpdate = {
        price: listEdit.price,
        inside: '',
        outside: '',
        both: '',
      };
    }
    reset(formUpdate);
  }, [isTypeSubService]);

  useEffect(() => {
    if (listEdit) {
      setOptions(listEdit.isActive === true ? 'ACTIVE' : 'ISACTIVE');
      setOptionSelected(listEdit.serviceGroup);
    }
  }, [listEdit, up]);

  useEffect(() => {
    listEdit !== undefined ? setIsTypeSubService(!listEdit?.isOther) : setIsTypeSubService(false);

    if (listEdit?.isOther === undefined) {
      setIsTypeSubService(false);
    } else {
      setIsTypeSubService(!listEdit?.isOther);
    }
  }, [listEdit]);

  const createSubmit = (data) => {
    if (!data.name || !data.name.trim()) {
      dispatch(digLogAction.getTitle({ title: 'Name is required' }));
      return false;
    }
    if (data.price) {
      if (data.price < 0) {
        dispatch(digLogAction.getTitle({ title: getTitleMessagePrice('Price', errorsResponse.PRICE.POSITIVE) }));
        return false;
      }
    }
    if (data.inside) {
      if (data.inside < 0) {
        dispatch(digLogAction.getTitle({ title: getTitleMessagePrice('Inside', errorsResponse.PRICE.POSITIVE) }));
        return false;
      }
    }
    if (data.outside) {
      if (data.outside < 0) {
        dispatch(digLogAction.getTitle({ title: getTitleMessagePrice('Outside', errorsResponse.PRICE.POSITIVE) }));
        return false;
      }
    }
    if (data.both) {
      if (data.both < 0) {
        dispatch(digLogAction.getTitle({ title: getTitleMessagePrice('Both', errorsResponse.PRICE.POSITIVE) }));
        return false;
      }
    }
    if (!fileUrl) {
      if (!listEdit?.logo) {
        dispatch(digLogAction.getTitle({ title: contentDialog.IMAGE.NOT_IMAGE }));
        return false;
      }
    }

    const serviceInput = !isTypeSubService
      ? {
          name: data.name.trim(),
          isActive:
            serviceDropdown.keys[options] === false
              ? false
              : serviceDropdown.keys[options] === true
              ? true
              : listEdit?.isActive === false
              ? false
              : true,
          typeServices: [{ name: 'OTHER', price: !isTypeSubService ? data?.price : null }],
          serviceGroupId: optionSelected
            ? optionSelected
            : listEdit
            ? listEdit.serviceGroup
            : Object.keys(optionsServices)[0],
          note: data.note?.trim(),
          emptySlot1: data.emptySlot1?.trim(),
          emptySlot2: data.emptySlot2?.trim(),
          emptySlot3: data.emptySlot3?.trim(),
          emptySlot4: data.emptySlot4?.trim(),
          emptySlot5: data.emptySlot5?.trim(),
        }
      : {
          name: data.name.trim(),
          isActive:
            serviceDropdown.keys[options] === false
              ? false
              : serviceDropdown.keys[options] === true
              ? true
              : listEdit?.isActive === false
              ? false
              : true,
          typeServices: [
            {
              name: 'INSIDE',
              price: !isTypeSubService ? null : data?.inside,
            },
            {
              name: 'OUTSIDE',
              price: !isTypeSubService ? null : data?.outside,
            },
            { name: 'BOTH', price: !isTypeSubService ? null : data?.both },
          ],
          serviceGroupId: optionSelected
            ? optionSelected
            : listEdit
            ? listEdit.serviceGroup
            : Object.keys(optionsServices)[0],
          note: data.note?.trim(),
          emptySlot1: data.emptySlot1?.trim(),
          emptySlot2: data.emptySlot2?.trim(),
          emptySlot3: data.emptySlot3?.trim(),
          emptySlot4: data.emptySlot4?.trim(),
          emptySlot5: data.emptySlot5?.trim(),
        };
    console.log('serviceGroupId', optionSelected);
    listEdit === undefined
      ? dispatch(subServiceActions.createSubService({ newServer: serviceInput, fileUrl: imgFile }))
      : dispatch(
          subServiceActions.updateSubService({
            newServer: { id: listEdit.id, ...serviceInput },
            fileUrl: imgFile || 1,
            logoUpdate: listEdit?.logo,
          })
        );

    if (subServiceForm.current) {
      subServiceForm.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (showSubService === true) {
      setShow(true);
    }
  }, [showSubService]);

  useEffect(() => {
    if (addShow === true) {
      // reset();
      setShow(false);
    }
  }, [addShow]);

  useEffect(() => {
    reset(listEdit);
  }, [listEdit]);

  useEffect(() => {
    if (isShowMessage === true) {
      reset();
      setFileUrl(null);
      setOptions('ACTIVE');
      setIsTypeSubService(false);
      setUp(!up);
      setOptionSelected(listServiceAll[0]?._id);
    }
  }, [isShowMessage]);

  return (
    <>
      <div className={`${cx('crServiceContainer')} ${show ? cx('crServiceContainerShow') : cx('')}`}>
        <div className={cx('crWrapperService')} id='createSubService'>
          <div className={cx('crServiceContainerShow')}>
            <div className={cx('crContent')}>
              <div className={cx('crBtnClose')} onClick={handleCloseSidebar}>
                <img src={iconClose} alt='close' />
              </div>
              <div className={cx('crFormWrap')}>
                <span className={cx('crTitle')}> {title ? `Edit Sub Service ` : 'Create Sub Service'} </span>
                <div className={cx('crWrapLogo')}>
                  <div className={cx('crWrapAvatar')}>
                    <div className={cx('crLogo1')}>
                      <img src={fileUrl || listEdit?.logo || imgAvatar} alt='Avatar' className={cx('crLogo')} />
                    </div>
                    <div className={cx('crWrapIconEdit')}>
                      <input type='file' className={cx('crInputFile')} value='' onChange={processImage} />
                      <img src={iconUpdate} alt='' className={cx('crIconEdit')} />
                    </div>
                  </div>
                </div>

                <form className={cx('crForm')} onSubmit={handleSubmit(createSubmit)}>
                  <div className={cx('crInputGroup')} ref={subServiceForm}>
                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Sub Service Name*'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'name'}
                      type={'text'}
                      min={3}
                      max={50}
                      validation={{
                        required: 'Sub service name is required',
                      }}
                      onChange={(e) => setValue('name', e.target.value)}
                      classNameInput={`${cx('crValueInput')} ${cx('crValueServiceName')}`}
                      error={errors['name']}
                    />

                    <InputField
                      classNameWrap={cx('crFormCheckBox')}
                      classNameLabel={cx('crTitleCheckBox')}
                      classNameInput={cx('crCheckedInput')}
                      label={'Type'}
                      type={'checkbox'}
                      setIsTypeService={setIsTypeSubService}
                      check={isTypeSubService}
                    />

                    {isTypeSubService ? (
                      <>
                        <InputField
                          classNameWrap={cx('crFormInput')}
                          label={'Inside($)*'}
                          classNameLabel={cx('crTitleInput')}
                          register={isTypeSubService ? register : null}
                          name={'inside'}
                          type={'number'}
                          classNameInput={cx('crValueInput')}
                          validation={{
                            required: 'Inside name is required field',
                          }}
                          onChange={(e) => setValue('inside', e.target.value)}
                          error={errors['inside']}
                        />

                        <InputField
                          classNameWrap={cx('crFormInput')}
                          label={'Outside($)*'}
                          classNameLabel={cx('crTitleInput')}
                          register={register}
                          name={'outside'}
                          type={'number'}
                          classNameInput={cx('crValueInput')}
                          validation={{
                            required: 'Outside is required',
                          }}
                          onChange={(e) => setValue('outside', e.target.value)}
                          error={errors['outside']}
                        />

                        <InputField
                          classNameWrap={cx('crFormInput')}
                          label={'Both($)*'}
                          classNameLabel={cx('crTitleInput')}
                          register={register}
                          name={'both'}
                          type={'number'}
                          classNameInput={cx('crValueInput')}
                          validation={{
                            required: 'Both is required',
                          }}
                          onChange={(e) => setValue('both', e.target.value)}
                          error={errors['both']}
                        />
                      </>
                    ) : (
                      <>
                        <InputField
                          classNameWrap={cx('crFormInput')}
                          label={'Price($)*'}
                          classNameLabel={cx('crTitleInput')}
                          register={register}
                          name={'price'}
                          type={'number'}
                          classNameInput={cx('crValueInput')}
                          validation={{
                            required: 'Price is required',
                          }}
                          onChange={(e) => setValue('price', e.target.value)}
                          error={errors['price']}
                        />
                      </>
                    )}

                    <div className={cx('crFormInput')}>
                      <label className={cx('crTitleInput')}>Status</label>
                      <Dropdown
                        name='select'
                        className={cx('crValueInput')}
                        selected={serviceDropdown.values}
                        options={options}
                        up={up}
                        valueUpdate={listEdit === undefined ? null : serviceDropdown.edits[listEdit.isActive]}
                        setOptions={setOptions}
                      />
                    </div>

                    <div className={cx('crFormInput')}>
                      <label className={cx('crTitleInput')}>Services</label>
                      <Dropdown
                        name='select'
                        className={cx('crValueInput')}
                        selected={optionsServices}
                        options={optionSelected}
                        up={up}
                        valueUpdate={listServiceAll?.filter((item) => item._id === listEdit?.serviceGroup)[0]?.name}
                        setOptions={setOptionSelected}
                      />
                    </div>

                    <TextareaField
                      classNameWrap={cx('crFormTextarea')}
                      label={'Note'}
                      classNameLabel={cx('crTitleTextarea')}
                      register={register}
                      name={'note'}
                      onChange={(e) => setValue('note', e.target.value)}
                      classNameTextarea={`${cx('crValueTextarea')}`}
                    />

                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Empty Slot'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'emptySlot1'}
                      type={'text'}
                      onChange={(e) => setValue('emptySlot1', e.target.value)}
                      classNameInput={`${cx('crValueInput')}`}
                    />

                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Empty Slot'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'emptySlot2'}
                      type={'text'}
                      onChange={(e) => setValue('emptySlot2', e.target.value)}
                      classNameInput={`${cx('crValueInput')}`}
                    />
                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Empty Slot'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'emptySlot3'}
                      type={'text'}
                      onChange={(e) => setValue('emptySlot3', e.target.value)}
                      classNameInput={`${cx('crValueInput')}`}
                    />
                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Empty Slot'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'emptySlot4'}
                      type={'text'}
                      onChange={(e) => setValue('emptySlot4', e.target.value)}
                      classNameInput={`${cx('crValueInput')}`}
                    />
                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Empty Slot'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'emptySlot5'}
                      type={'text'}
                      onChange={(e) => setValue('emptySlot5', e.target.value)}
                      classNameInput={`${cx('crValueInput')}`}
                    />
                  </div>

                  <div className={cx('crWrapBtn')}>
                    {listEdit === undefined ? (
                      <button type='submit' disabled={loading || isShowMessage} className={cx('btnCreate')}>
                        Create
                      </button>
                    ) : (
                      <button type='submit' disabled={loading || isShowMessage} className={cx('btnCreate')}>
                        Save
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSidebar || showSubService ? <div className={cx('Overlay')} onClick={handleCloseSidebar}></div> : null}
    </>
  );
};

export default CreateSubService;
