import { all } from 'redux-saga/effects';
import serviceSaga from 'src/features/superAdmin/service/saga';
import subServiceSaga from 'src/features/superAdmin/subService/saga';
import combineServiceSaga from 'src/features/superAdmin/combineService/saga';
import householdSaga from 'src/features/superAdmin/household/saga';
import userManagementSaga from 'src/features/superAdmin/userManagement/saga';
import authSaga from 'src/features/auth/authSaga';
import requestSaga from 'src/features/superAdmin/requests/saga';

export default function* rootSaga() {
  yield all([
    serviceSaga(),
    subServiceSaga(),
    combineServiceSaga(),
    householdSaga(),
    requestSaga(),
    userManagementSaga(),
    authSaga(),
  ]);
}
