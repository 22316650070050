//Library
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
//Components
import Search from 'src/components/common/search';
import TableHeaderUser from './tableHeaderUser';
import TableItemUser from './tableItemUser';
import TableItemUserTab from './tableItemUserTab';
import PagiNation from 'src/components/common/pagination/Pagination';
import CreateUser from '../createUser';
import NoDataAvailable from 'src/components/common/noDataAvailable/NoDataAvaliable';
//Feature
import {
  selectUserCheckShow,
  selectUserFilter,
  selectUserIsSuccess,
  selectUserList,
  selectUserMessage,
  selectUserPaginationTotal,
  selectUserShowMessage,
  selectUserTotal,
  selectUserTypeAction,
  userManagementAction,
} from 'src/features/superAdmin/userManagement/slice';

//Utils
//Icon

//Scss
import styles from './styles.module.scss';
import { digLogAction } from 'src/features/digLog/slice';
import { contentDialog } from 'src/utils/contentDialog';
import { handleErrorMessages } from 'src/utils/help';
import { selectTotalRequest } from 'src/features/superAdmin/requests/slice';

const cx = classNames.bind(styles);

const ListUserManagement = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState();
  /**
   * useSelector Block
   */
  const total = useSelector(selectUserTotal);
  const paginationTotal = useSelector(selectUserPaginationTotal);
  const userList = useSelector(selectUserList);
  const filter = useSelector(selectUserFilter);
  const isSuccess = useSelector(selectUserIsSuccess);
  const checkShow = useSelector(selectUserCheckShow);
  const typeAction = useSelector(selectUserTypeAction);
  const isShowMessage = useSelector(selectUserShowMessage);
  const message = useSelector(selectUserMessage);

  /**
   * Functions handle logic
   */
  const handleShow = () => {
    setShow(!show);
  };

  const handleSearch = (value) => {
    dispatch(userManagementAction.filter({ ...filter, textSearch: value, page: 1 }));
  };

  const handlePageChange = (values) => {
    dispatch(userManagementAction.filter(values));
  };
  /**
   * useEffect
   */

  useEffect(() => {
    dispatch(userManagementAction.resetState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(userManagementAction.getAllUserManagement(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    if (checkShow === true) {
      setShow(true);
    }
    if (checkShow === false) {
      setShow(false);
    }
  }, [isSuccess, checkShow, dispatch]);

  // Handle Show Dialog
  useEffect(() => {
    if (isShowMessage === true && typeAction === 'CREATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.CREATE_USER_MANAGEMENT.SUCCESS,
          function: () => {
            dispatch(userManagementAction.resetStatus());
            dispatch(userManagementAction.getAllUserManagement(filter));
          },
        })
      );
    } else if (isShowMessage === true && typeAction === 'UPDATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.UPDATE_USER_MANAGEMENT.SUCCESS,
          function: () => {
            dispatch(userManagementAction.resetStatus());
            dispatch(userManagementAction.getAllUserManagement(filter));
          },
        })
      );
    } else if (isShowMessage === true && typeAction === 'DELETE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.DELETE_USER_MANAGEMENT.SUCCESS,
          function: () => {
            dispatch(userManagementAction.resetStatus());
            dispatch(userManagementAction.getAllUserManagement(filter));
          },
        })
      );
    } else if (isShowMessage === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(userManagementAction.resetStatus());
          },
        })
      );
    }
  }, [dispatch, filter, isShowMessage, message, typeAction]);

  return (
    <>
      <CreateUser show={show} edit={edit} setEdit={setEdit} setShow={setShow} />
      <div className={cx('user')}>
        Users: {total || '0'}
        <button className={cx('btnAdd')} onClick={handleShow}>
          <p>+</p>
          <span>Add New User</span>
        </button>
      </div>
      <div className={cx('wrap')}>
        <div className={cx('filter')}>
          <div>{/* <Search handleSearch={handleSearch} placeholder='Search by User Name' /> */}</div>
        </div>
        <div className={cx('desk')}>
          <TableHeaderUser />
          <div className={cx('item')}>
            {paginationTotal > 0 ? (
              userList?.map((value, i) => (
                <TableItemUser
                  key={i}
                  {...value}
                  filter={filter}
                  edit={edit}
                  setEdit={setEdit}
                  setShow={setShow}
                  handleShow={handleShow}
                />
              ))
            ) : (
              <NoDataAvailable />
            )}
          </div>
        </div>
        <div>
          {paginationTotal > 0 ? (
            <div className={cx('tab')}>
              {userList?.map((value, i) => (
                <TableItemUserTab
                  key={i}
                  {...value}
                  filter={filter}
                  edit={edit}
                  setEdit={setEdit}
                  setShow={setShow}
                  handleShow={handleShow}
                />
              ))}
            </div>
          ) : (
            <NoDataAvailable />
          )}
        </div>
        <div className={cx('lIPagination')}>
          {total > 0 && <PagiNation filter={filter} total={paginationTotal} handlePageChange={handlePageChange} />}
        </div>
      </div>
    </>
  );
};

export default ListUserManagement;
