// component
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// icons
import iconClose from 'src/assets/icons/ic_close.svg';

import Overlay from 'src/components/common/overlay/Overlay';
import Dropdown from 'src/components/dropdown';
import InputField from 'src/components/inputField';
import {
  houseActions,
  selectHouseholdIsSuccess,
  selectHouseholdLoading,
  selectHouseholdOpenForm,
  selectHouseholdResetForm,
  selectHouseholdShowMessage,
} from 'src/features/superAdmin/household/slice';
// Utils
import { EMAIL_REGEX, PHONE_NUMBER_CANADA, POSTAL_CODE_CANADA } from 'src/utils/constants';
import { houseHoldDropdown } from 'src/utils/enum';
import { householdProvice } from 'src/utils/enum';
import { formatPhoneNumber, formatPostalCode } from 'src/utils/help';
// scss
import styles from './styles.modules.scss';

const CreateHouseHold = (props) => {
  const { showHouse, setShowHouse, listEdit, setListEdit } = props;
  const showAdd = useSelector(selectHouseholdOpenForm);
  const isSuccess = useSelector(selectHouseholdIsSuccess);
  const loading = useSelector(selectHouseholdLoading);
  const isShowMessage = useSelector(selectHouseholdShowMessage);

  const resetForm = useSelector(selectHouseholdResetForm);

  /**
   * UseEffect Block
   */
  const [options, setOptions] = useState(Object.keys(houseHoldDropdown.values)[0]);
  const [provice, setProvice] = useState(Object.keys(householdProvice.values)[0]);
  const [dropOne, setDropOne] = useState(false);
  const [dropTwo, setDropTwo] = useState(false);
  const [showDropdown, setShowDropdown] = useState([]);
  const [up, setUp] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const dispatch = useDispatch();
  const cx = classNames.bind(styles);

  useEffect(() => {
    const arr = [...Array(2)].map((value) => false);
    setShowDropdown(arr);
  }, []);

  const handleDropdown = (index) => {
    const arr = [...Array(2)].map((value) => false);
    arr[index] = true;
    setShowDropdown(arr);
  };

  // Set data for input
  const formUpdate = listEdit && {
    firstName: listEdit.name.firstName,
    lastName: listEdit.name.lastName,
    email: listEdit.email,
    phone: listEdit.phoneNumber.phone,
    houseSize: listEdit.houseSize,
    city: listEdit.address.city,
    streetAddress: listEdit.address.streetAddress,
    provice: listEdit.address.provice,
    postalCode: listEdit.address.postalCode,
  };

  const createSubmit = (data) => {
    const formData = {
      name: {
        firstName: data.firstName,
        lastName: data.lastName,
      },
      email: data.email,
      phoneNumber: {
        countryCode: '+1',
        phone: data.phone,
      },
      houseSize: options ? options : listEdit?.houseSize ? listEdit?.houseSize : 'SMALL',
      address: {
        city: data.city,
        streetAddress: data.streetAddress,
        provice: provice ? provice : listEdit?.address?.provice,
        postalCode: formatPostalCode(data.postalCode),
      },
      _id: listEdit !== undefined ? listEdit._id : null,
    };

    if (listEdit === undefined) {
      dispatch(houseActions.createHousehold(formData));
    } else {
      dispatch(houseActions.updateHousehold(formData));
    }
  };

  useEffect(() => {
    reset(formUpdate);
  }, [listEdit]);

  /** reset form when update success */

  useEffect(() => {
    if (showAdd === true) {
      reset({});
      setListEdit();
      setOptions(Object.keys(houseHoldDropdown.values)[0]);
      setProvice(Object.keys(householdProvice.values)[0]);
      setShowHouse(false);
      dispatch(houseActions.cloneForm());
      setUp(!up);
    }
  }, [showAdd]);

  /** when create success -> clear form, false -> keep form */
  useEffect(() => {
    if (isSuccess === false) {
      reset();
    }
  }, [isSuccess]);

  // useEffect(() => {
  //   if (resetForm === true) {
  //     setShowHouse(false);
  //     reset();
  //     setListEdit();
  //     setOptions();
  //     setProvice();
  //     dispatch(houseActions.resetFormClose());
  //   }
  // }, [resetForm]);

  const handleCloseSidebar = () => {
    if (listEdit === undefined) {
      reset();
      setListEdit();
      setOptions(Object.keys(houseHoldDropdown.values)[0]);
      setProvice(Object.keys(householdProvice.values)[0]);
      setShowHouse(false);
      setUp(!up);
    } else {
      reset({});
      setListEdit();
      setOptions();
      setProvice();
      setShowHouse(false);
      setUp(!up);
    }
  };

  useEffect(() => {
    if (listEdit) {
      setOptions(listEdit.houseSize);
      setProvice(listEdit.address?.provice);
    }
  }, [listEdit, up]);

  return (
    <>
      <div
        className={`${cx('crHouseContainer')} ${showHouse ? cx('crHouseContainerShow') : cx('')}`}
        onClick={() => {
          if (dropOne) {
            setDropOne(false);
          } else if (dropTwo) {
            setDropTwo(false);
          }
        }}
      >
        <div className={cx('crContent')}>
          <div className={cx('crClose')}>
            <div className={cx('crButton')} onClick={handleCloseSidebar}>
              <img src={iconClose} alt='iconClose' />
            </div>
          </div>

          <div className={cx('crFormWrap')}>
            {listEdit === undefined ? (
              <span className={cx('crTitle')}>Create New Household</span>
            ) : (
              <span className={cx('crTitle')}>Edit Household</span>
            )}
            <form className={cx('crForm')} onSubmit={handleSubmit(createSubmit)}>
              <InputField
                label={'First Name*'}
                classNameWrap={cx('crFormInput')}
                classNameLabel={cx('crTitleInput')}
                classNameInput={cx('crValueInput crFirstName')}
                type={'text'}
                register={register}
                name={'firstName'}
                validation={{
                  required: 'First name is required',
                }}
                onChange={(e) => setValue('firstName', e.target.value)}
                error={errors['firstName']}
              />

              <InputField
                label={'Last Name*'}
                classNameWrap={cx('crFormInput')}
                classNameLabel={cx('crTitleInput')}
                classNameInput={cx('crValueInput crLastName')}
                type={'text'}
                register={register}
                name={'lastName'}
                validation={{
                  required: 'Last name is required',
                }}
                onChange={(e) => setValue('lastName', e.target.value)}
                error={errors['lastName']}
              />

              <InputField
                label={'Email*'}
                classNameWrap={cx('crFormInput')}
                classNameLabel={cx('crTitleInput')}
                classNameInput={cx('crValueInput')}
                type={'email'}
                register={register}
                name={'email'}
                validation={{
                  required: 'Email is required',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Email format is not valid',
                  },
                }}
                onChange={(e) => setValue('email', e.target.value)}
                error={errors['email']}
              />

              <InputField
                label={'Phone Number*'}
                classNameWrap={cx('crFormInput')}
                classNameLabel={'crTitleInput'}
                register={register}
                name={'phone'}
                type={'text'}
                max={12}
                classNameInput={cx('crValueInput')}
                placeholder={'000-000-0000'}
                onChange={(e) => {
                  setValue('phone', `${formatPhoneNumber(e.target.value)}`);
                }}
                validation={{
                  required: 'Phone number a required',
                  pattern: {
                    value: PHONE_NUMBER_CANADA,
                    message: 'Phone Number format is not valid',
                  },
                }}
                error={errors['phone']}
              />

              <div className={cx('crFormInput')}>
                <label htmlFor='input' className={cx('crTitleInput')}>
                  Size
                </label>
                <Dropdown
                  register={register}
                  name={'houseSize'}
                  selected={houseHoldDropdown.values}
                  options={options}
                  setOptions={setOptions}
                  valueUpdate={houseHoldDropdown.values[listEdit === undefined ? null : listEdit.houseSize]}
                  show={showDropdown[0]}
                  index={0}
                  up={up}
                  setshow={handleDropdown}
                />
              </div>

              <InputField
                label={'Street*'}
                classNameWrap={cx('crFormInput')}
                classNameLabel={cx('crTitleInput')}
                classNameInput={cx('crValueInput crStreet')}
                type={'text'}
                register={register}
                name={'streetAddress'}
                validation={{
                  required: 'Street is required',
                }}
                onChange={(e) => setValue('streetAddress', e.target.value)}
                error={errors['streetAddress']}
              />

              <InputField
                label={'City*'}
                classNameWrap={cx('crFormInput')}
                classNameLabel={cx('crTitleInput')}
                classNameInput={cx('crValueInput crCity')}
                type={'text'}
                register={register}
                name={'city'}
                validation={{
                  required: 'City is required',
                }}
                onChange={(e) => setValue('city', e.target.value)}
                error={errors['city']}
              />

              <div className={cx('crFormInput')}>
                <label htmlFor='input' className={cx('crTitleInput')}>
                  Province
                </label>
                <Dropdown
                  register={register}
                  name={'province'}
                  className={cx('crProvince')}
                  selected={householdProvice.values}
                  options={provice}
                  up={up}
                  setOptions={setProvice}
                  valueUpdateProvince={
                    householdProvice.values[listEdit === undefined ? null : listEdit.address.provice]
                  }
                  show={showDropdown[1]}
                  index={1}
                  setshow={handleDropdown}
                />
              </div>

              <InputField
                label={'Postal Code*'}
                classNameWrap={cx('crFormInput')}
                classNameLabel={cx('crTitleInput')}
                classNameInput={cx('crValueInput crValuePostalCode')}
                type={'text'}
                register={register}
                validation={{
                  required: 'Postal code is required',
                  pattern: {
                    value: POSTAL_CODE_CANADA,
                    message: 'Postal code format is not valid',
                  },
                }}
                name={'postalCode'}
                max={7}
                placeholder={'X0X 0X0'}
                onChange={(e) => {
                  setValue('postalCode', `${formatPostalCode(e.target.value)}`);
                }}
                error={errors['postalCode']}
              />

              <div className={cx('hhCrButton')}>
                {listEdit === undefined ? (
                  <button type='submit' disabled={loading || isShowMessage} className={cx('btnCreate')}>
                    Create
                  </button>
                ) : (
                  <button type='submit' disabled={loading || isShowMessage} className={cx('btnCreate')}>
                    Save
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {showHouse && (
        <Overlay showHouse={showHouse} setShowHouse={setShowHouse} handleCloseSidebar={handleCloseSidebar} />
      )}
    </>
  );
};

export default CreateHouseHold;
