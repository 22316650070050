import { userManagementApi } from 'src/utils/constants';
import axiosClient from '../axiosClient';

const userApi = {
  getAllUser(params) {
    const url = `/${userManagementApi.getUserManagement}`;
    return axiosClient.get(url, { params });
  },
  createUser(data) {
    const url = `/${userManagementApi.getUserManagement}`;
    return axiosClient.post(url, data);
  },
  updateUser(data) {
    const url = `/${userManagementApi.getUserManagement}/${data._id}`;
    return axiosClient.put(url, data);
  },
  deleteUser(id) {
    const url = `/${userManagementApi.getUserManagement}/${id}`;
    return axiosClient.delete(url, id);
  },
};

export default userApi;
