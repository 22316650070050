import classNames from 'classnames/bind';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

//import slide
import { digLogAction } from 'src/features/digLog/slice';
import { requestActions } from 'src/features/superAdmin/requests/slice';

//import component
import CreateHouseHold from 'src/pages/superAdmin/household/create';
//import icon
import iconCheckBox from 'src/assets/icons/ic_check_box.svg';
import iconDeleteBox from 'src/assets/icons/ic_delete_box.svg';
import iconEdit from 'src/assets/icons/ic_edit.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';

//import image

//import scss
import styles from './styles.module.scss';

// Help
import { convertTimeRequestToLocal } from 'src/utils/help';
import { FilterStatusRequest } from 'src/utils/enum';
import { contentDialog } from 'src/utils/contentDialog';

const TableCardMob = (props) => {
  const propsItem = props?.props;
  const { setIsEdit, setListEdit } = props;
  const cx = classNames.bind(styles);
  const dispatch = useDispatch();

  const dateTime = convertTimeRequestToLocal(propsItem?.calendar);

  const [show, setshow] = useState(props?.isOpen);
  // const [active, setActive] = useState(propsItem?.status);

  const { householdId, nameTypeService, _id, nameHousehold } = props?.props;
  useEffect(() => {
    setshow(props?.isOpen);
  }, [props?.isOpen]);

  const handleComplete = (event) => {
    // console.log(`[handleComplete] event -> ${JSON.stringify(event.target.value)}`);

    console.log(`[handleComplete] dispatch -> updateRequest`);
    // dispatch(requestActions.updateRequest({ _id: propsItem._id, body: { status: event.target.value } }));
    dispatch(
      digLogAction.getComFig({
        title: 'Are you sure you want complete the request?',
        function: () => {
          dispatch(
            requestActions.updateRequest({ _id: propsItem?._id, body: { status: FilterStatusRequest.keys.COMPLETE } })
          );
        },
        buttonTitle: {
          oke: 'Yes',
          cancel: 'No',
        },
      })
    );
  };

  const handleEditOrder = () => {
    setListEdit(propsItem);
    setIsEdit(true);
  };

  const handleDeleteOrder = () => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE.CLICK,
        function: () => {
          dispatch(requestActions.deleteRequest({ _id: propsItem._id, isActive: false }));
        },
      })
    );
  };

  return (
    <div id='itHhHdHeaderItemMob' className={cx('itHdTableBlock')}>
      <div className={cx('itHhHdItemHeadWrap')}>
        <div className={cx('itHhHdTitleDetail')}>Order Detail</div>
        <div className={cx('itHhHdItemRequest')}>
          <div className={cx('itHhHdBtnGroupEdit')}>
            <img src={iconEdit} alt='editIcon' className={cx('itHdButtonEdit')} onClick={handleEditOrder} />
          </div>
          <div className={cx('itHhHdBtnGroupDelete')}>
            <img src={iconDelete} alt='trashIcon' className={cx('itHhHdButtonDelete')} onClick={handleDeleteOrder} />
          </div>
        </div>
      </div>

      <div className={cx('itHhHdTableItem')}>
        <div className={cx('itHhHdTitle')}>Household ID</div>
        <div title={householdId || 'N/A'}>{householdId || 'N/A'}</div>
      </div>
      <div className={cx('itHhHdTableItem')}>
        <div className={cx('itHhHdTitle')}>Household Name</div>
        <div title={nameHousehold?.firstName.concat(' ', propsItem?.nameHousehold?.lastName) || 'N/A'}>
          {nameHousehold?.firstName.concat(' ', propsItem?.nameHousehold?.lastName) || 'N/A'}
        </div>
      </div>
      <div className={cx('itHhHdTableItem')}>
        <div className={cx('itHhHdTitle')}>Date, Time</div>
        <div className={cx('itSpanItem')} title={dateTime || 'N/A'}>
          {dateTime || 'N/A'}
        </div>
      </div>

      <div className={cx('itHhHdTableItem')}>
        <div className={cx('itHhHdTitle')}>Status</div>
        <div className={cx('itHhItemRequest')}>
          {propsItem?.status === FilterStatusRequest?.keys?.INCOMPLETE ? (
            <>
              <div className={cx('itBorderIconCheck')} onClick={handleComplete}>
                <img src={iconCheckBox} className={cx('itTbCheckboxCheck')} alt='icon' />
              </div>
            </>
          ) : (
            <>
              <div className={cx('itBorderIconDelete')}>
                <img src={iconDeleteBox} className={cx('itTbCheckbox')} alt='icon' />
              </div>
            </>
          )}
        </div>
      </div>
      <div className={cx('itHhHdTableItem')}>
        <div className={cx('itHhHdTitle')}>Address</div>
        <div className={cx('itSpanItem')} title={nameTypeService?.toLowerCase() || 'N/A'}>
          {propsItem?.infoCustomer?.streetAddress || 'N/A'}, {propsItem?.infoCustomer?.city || 'N/A'},{' '}
          {propsItem?.infoCustomer?.provice} {propsItem?.infoCustomer?.postalCode || 'N/A'}
        </div>
      </div>
      <div className={cx('itHhHdTableItemService')}>
        <div className={cx('itHhHdTitle')}>Services</div>
        <div className={cx('itSpanItem')} title={propsItem?.infoCustomer?.name || 'N/A'}>
          {propsItem.services.map((value, index) => (
            <div div className={cx('itSpanItemName')}>
              {value.type === 'BOTH' ? (
                <div className={cx('nameBoth')}>{value.name}</div>
              ) : value.type === 'OTHER' ? (
                <div className={cx('nameOther')}>{value.name}</div>
              ) : value.type === 'INSIDE' ? (
                <div className={cx('nameInside')}>{value.name}</div>
              ) : (
                <div className={cx('nameOutside')}>{value.name}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={cx('itHhHdTableItem')}>
        <div className={cx('itHhHdTitle')}>Price ($)</div>
        <div className={cx('itSpanItem')} title={nameTypeService?.toLowerCase() || 'N/A'}>
          {propsItem?.total || 'N/A'}
        </div>
      </div>
    </div>
  );
};

export default TableCardMob;
