// Library
import React from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

// Utils
import { routerPaths } from 'src/utils/routers';

// Images
import imgBackground from 'src/assets/images/img_background_not_found.png';

// Scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function PageNotFound() {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate(`/${routerPaths.SUPER_ADMIN}/${routerPaths.SERVICES}/${routerPaths.LIST}`);
  };

  return (
    <div className={cx('pnfWrapper')}>
      <div className={cx('pnfContent')}>
        <img src={imgBackground} alt='bgNotFound' className={cx('pnfImg')} />
        <div className={cx('pnfError')}>404</div>
        <h3 className={cx('pnfTitle')}>Ooups, page not found</h3>
        <p className={cx('pnfDesc')}>
          We are very sorry for the inconvenience. It looks like you{`'`}re trying to <br /> access a page that has been
          deleted or never even existed.
        </p>
        <button className={cx('pnfBtnHomePage')} onClick={handleBackToHome}>
          Back to homepage
        </button>
      </div>
    </div>
  );
}

export default PageNotFound;
