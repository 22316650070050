import React from 'react';
import classNames from 'classnames/bind';

// Image
import adminBackgroundImg from 'src/assets/images/img_admin_background.jpg';

// Scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const BackGroundLogin = () => {
  return (
    <div className={cx('login-wrapper')}>
      <img src={adminBackgroundImg} alt='background-admin' className={cx('login-img')} />
    </div>
  );
};

export default BackGroundLogin;
