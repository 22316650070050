import classNames from 'classnames/bind';
import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Slice
import { requestActions, selectFilterRequest, selectIsSuccessRequest } from 'src/features/superAdmin/requests/slice';
import { digLogAction, selectDialogButtonTitle } from 'src/features/digLog/slice';

//import components

// Utils
import { convertTimeRequestToLocal } from 'src/utils/help';
import helpFunction from 'src/utils/help';
import { FilterStatusRequest, FilterTypeRequest } from 'src/utils/enum';

//import icon
import iconDeleteBox from 'src/assets/icons/ic_delete_box.svg';
import iconCheckBox from 'src/assets/icons/ic_check_box.svg';
import iconUp from 'src/assets/icons/ic_up.svg';
import iconTop from 'src/assets/icons/ic_top.svg';
import iconEdit from 'src/assets/icons/ic_edit.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';

//import image

//scss
import styles from './styles.module.scss';
import { contentDialog } from 'src/utils/contentDialog';

const TableItemRequest = (props) => {
  const propsItem = props.props;
  const { setIsEdit, setListEdit } = props;

  const cx = classNames.bind(styles);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateTime = convertTimeRequestToLocal(propsItem?.calendar);

  const status = useSelector(selectIsSuccessRequest);
  const filter = useSelector(selectFilterRequest);

  const details = useRef();

  const [show, setShow] = useState(props.isOpen);
  const [active, setActive] = useState(propsItem.status);
  // console.log(`[handleComplete] active -> ${JSON.stringify(active, null, 2)}`);

  const { householdId, nameTypeService } = props.props;
  useEffect(() => {
    setShow(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    setActive(propsItem.status);
  }, []);

  useEffect(() => {
    if (status === true) {
      dispatch(requestActions.getAllRequest({ ...filter }));
    }
  }, [dispatch, filter, status]);

  // Handle logic
  const handleComplete = (event) => {
    // dispatch(requestActions.updateRequest({ _id: propsItem._id, body: { status: event.target.value } }));
    dispatch(
      digLogAction.getComFig({
        title: 'Are you sure you want to mark this request as complete?',
        function: () => {
          dispatch(
            requestActions.updateRequest({ _id: propsItem._id, body: { status: event.target.value }, navigate })
          );
        },
        buttonTitle: {
          oke: 'Ok',
          cancel: 'Cancel',
        },
      })
    );
  };

  const handleEditOrder = () => {
    setListEdit(propsItem);
    setIsEdit(true);
  };

  const handleDeleteOrder = () => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE.CLICK,
        function: () => {
          dispatch(requestActions.deleteRequest({ _id: propsItem._id, isActive: false }));
        },
      })
    );
  };

  // Handle click outside
  helpFunction.useOutside(details, setShow);

  return (
    <>
      {!show && (
        <div className={cx('itHhTableItem')}>
          <p className={cx('itHhTbRequest')} title={householdId || 'N/A'}>
            {householdId || 'N/A'}
          </p>
          <p
            className={cx('itHhTbRequest')}
            title={propsItem?.nameHousehold?.firstName.concat(' ', propsItem?.nameHousehold?.lastName) || 'N/A'}
          >
            {propsItem?.nameHousehold?.firstName.concat(' ', propsItem?.nameHousehold?.lastName) || 'N/A'}
          </p>
          <p className={cx('itHhTbRequest')} title={dateTime || 'N/A'}>
            {dateTime || 'N/A'}
          </p>

          <div className={cx('itHhItemRequest')}>
            {propsItem.status === FilterStatusRequest.keys.INCOMPLETE ? (
              <button className={cx('rqBntActive')} onClick={handleComplete} value={FilterStatusRequest.keys.COMPLETE}>
                Incomplete
              </button>
            ) : (
              <button
                className={cx('rqBntIsActive')}
                // onClick={handleComplete}
                // value={FilterStatusRequest.keys.INCOMPLETE}
              >
                Complete
              </button>
            )}
          </div>

          <div className={cx('itHhItemRequestActions')}>
            {propsItem.status === FilterStatusRequest.keys.INCOMPLETE ? (
              <div className={cx('itHhBtnGroupEdit')} onClick={handleEditOrder}>
                <img src={iconEdit} alt='editIcon' className={cx('itHhButtonEdit')} />
              </div>
            ) : (
              <div className={cx('itHhBtnGroupEditHidden')}></div>
            )}

            <div className={cx('itHhBtnGroupDelete')} onClick={handleDeleteOrder}>
              <img src={iconDelete} alt='iconDelete' className={cx('itHhButtonDelete')} />
            </div>
          </div>

          <div className={cx('itDetails')}>
            <img
              src={iconUp}
              alt='img'
              className={cx('itUp')}
              onClick={() => {
                setShow(!show);
                props?.handleOpen(props?.index);
              }}
            />
          </div>
        </div>
      )}

      {show && (
        <div className={cx('rqDetail')}>
          <div id={cx('rqDetailItemOne')} className={cx('rqDetailItem')}>
            <div className={cx('rqTitleDetail')}>Order Detail</div>
            <div></div>
            <div></div>
            <div className={cx('rqWrapActive')}>
              {propsItem.status === FilterStatusRequest.keys.INCOMPLETE ? (
                <button
                  className={cx('rqBntActive')}
                  onClick={handleComplete}
                  value={FilterStatusRequest.keys.COMPLETE}
                >
                  Incomplete
                </button>
              ) : (
                <button
                  className={cx('rqBntIsActive')}
                  // onClick={handleComplete}
                  // value={FilterStatusRequest.keys.INCOMPLETE}
                >
                  Complete
                </button>
              )}
            </div>

            <div className={cx('itHhItemRequestActions')}>
              {propsItem.status === FilterStatusRequest.keys.INCOMPLETE ? (
                <div className={cx('itHhBtnGroupEdit')} onClick={handleEditOrder}>
                  <img src={iconEdit} alt='editIcon' className={cx('itHhButtonEdit')} />
                </div>
              ) : (
                <div className={cx('itHhBtnGroupEditHidden')}></div>
              )}

              <div className={cx('itHhBtnGroupDelete')} onClick={handleDeleteOrder}>
                <img src={iconDelete} alt='iconDelete' className={cx('itHhButtonDelete')} />
              </div>
            </div>

            <div
              className={cx('rqDetailsIcon')}
              onClick={() => {
                setShow(!show);
              }}
            >
              <img src={iconTop} alt='img' className={cx('itUp')} />
            </div>
          </div>

          <div id={cx('rqDetailItemTow')} className={cx('rqDetailItem')}>
            <div className={cx('rqDetailItemTow')}>
              <div className={cx('rqDetailItemName')}>Household ID</div>
              <div className={cx('rqDetailValue')} title={householdId || 'N/A'}>
                {householdId || 'N/A'}
              </div>
            </div>
            <div className={cx('rqDetailItemTow')}>
              <div className={cx('rqDetailItemName')}>Household Name</div>
              <div
                className={cx('rqDetailValue')}
                title={propsItem?.nameHousehold?.firstName.concat(' ', propsItem?.nameHousehold?.lastName) || 'N/A'}
              >
                {propsItem?.nameHousehold?.firstName.concat(' ', propsItem?.nameHousehold?.lastName) || 'N/A'}
              </div>
            </div>
            <div className={cx('rqDetailItemThree')}>
              <div className={cx('rqDetailItemName')}>Date, Time</div>
              <div className={cx('rqDetailValue')} title={dateTime || 'N/A'}>
                {dateTime || 'N/A'}
              </div>
            </div>
          </div>

          <div id={cx('rqDetailItemThree3')} className={cx('rqDetailItem3')}>
            <div className={cx('rqDetailItemThree2')}>
              <div className={cx('rqDetailItemName')}>Address</div>
              <div className={cx('rqDetailValue')} title={dateTime || 'N/A'}>
                {propsItem?.infoCustomer?.streetAddress || 'N/A'}, {propsItem?.infoCustomer?.city || 'N/A'},{' '}
                {propsItem?.infoCustomer?.provice} {propsItem?.infoCustomer?.postalCode || 'N/A'}
              </div>
            </div>
          </div>

          <div id={cx('rqDetailItemTow')} className={cx('rqDetailItem1')}>
            <div className={cx('rqDetailItemThree1')}>
              <div className={cx('rqDetailItemName')}>Service Name</div>
              <div className={cx('rqDetailValue')} title={propsItem?.infoCustomer?.name || 'N/A'}>
                {propsItem.services.map((value, index) => (
                  <div key={index}>
                    {value.type === 'BOTH' ? (
                      <div className={cx('nameBoth')}>{value.name}</div>
                    ) : value.type === 'OTHER' ? (
                      <div className={cx('nameOther')}>{value.name}</div>
                    ) : value.type === 'INSIDE' ? (
                      <div className={cx('nameInside')}>{value.name}</div>
                    ) : (
                      <div className={cx('nameOutside')}>{value.name}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div id={cx('rqDetailItemThree3')} className={cx('rqDetailItem3')}>
            <div className={cx('rqDetailItemThree2')}>
              <div className={cx('rqDetailItemName')}>Price ($)</div>
              <div className={cx('rqDetailValue')} title={dateTime || 'N/A'}>
                {propsItem?.total || 'N/A'}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TableItemRequest;
