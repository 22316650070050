import React, { useState } from 'react';
import classNames from 'classnames/bind';

import styles from './style.module.scss';
import Sidebar from './sidebar';
import Header from '../components/header';
import { Outlet } from 'react-router-dom';

const AdminLayout = () => {
  const cx = classNames.bind(styles);

  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <div className={cx('adminLayout')}>
      <Sidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div className={cx('container')}>
        <div className={cx('header')}>
          <Header />
        </div>
        <div className={cx('content')}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
