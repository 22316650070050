import classNames from 'classnames/bind';
import React from 'react';

// scss
import styles from './styles.module.scss';

const TextareaField = (props) => {
  const {
    register,
    name,
    placeholder,
    classNameWrap,
    min,
    max,
    classNameTextarea,
    label,
    classNameLabel,
    onChange,
    value,
    readonly,
    validation,
    error,
  } = props;
  const cx = classNames.bind(styles);

  return (
    <div className={cx(`${classNameWrap}`)}>
      {label && <label className={cx(`${classNameLabel}`)}>{label}</label>}
      <textarea
        className={cx(`${classNameTextarea}`)}
        {...register(`${name}`, validation)}
        placeholder={placeholder}
        value={value && value}
        readOnly={readonly}
        minLength={min}
        maxLength={max}
        onChange={(e) => onChange && onChange(e)}
      ></textarea>
      {error && <p className={cx('ifValidateText')}>{error.message}</p>}
    </div>
  );
};

export default TextareaField;
