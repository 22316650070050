// library
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';

// icons
import iconNextDown from 'src/assets/icons/ic_next_down.svg';
import iconNextUp from 'src/assets/icons/ic_next_up.svg';
import iconNextDownWhite from 'src/assets/icons/ic_next_down_write.svg';
import iconNextUpWhite from 'src/assets/icons/ic_next_up_write.svg';

// scss
import styles from './styles.module.scss';
const cx = classNames.bind(styles);

const PagiNation = (props) => {
  const { filter, handlePageChange, total } = props;
  const { page, limit } = filter;
  const [totalid, settotalid] = useState();

  const totalPage = Math.ceil(total / limit) || 1;

  // useEffect(() => {
  //   console.log('check page', page);
  // }, [total]);

  useEffect(() => {
    settotalid(total);
    if (total % 10 === 0 && totalid > total) {
      const newFilter = {
        ...filter,
        page: total / 10,
      };
      handlePageChange(newFilter);
    }
  }, [total]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const goToFirstPage = () => {
    if (page === 1) return;
    const newFilter = {
      ...filter,
      page: 1,
    };
    handlePageChange(newFilter);
    scrollToTop();
  };

  const goToPreviousPage = () => {
    if (page === 1) return;
    const newFilter = {
      ...filter,
      page: page - 1,
    };
    handlePageChange(newFilter);
    scrollToTop();
  };

  const nextPage = () => {
    if (page === totalPage) return;
    const newFilter = {
      ...filter,
      page: page + 1,
    };
    handlePageChange(newFilter);
    scrollToTop();
  };

  const goToEndPage = () => {
    if (page === totalPage) return;
    const newFilter = {
      ...filter,
      page: totalPage,
    };
    handlePageChange(newFilter);
    scrollToTop();
  };

  return totalPage >= 1 ? (
    <div id='pnPagination' className={cx('pnPagination')}>
      <div className={cx('pnNextPage')}>
        <button className={page !== 1 ? cx('pnDoubleDownMax') : cx('pnDoubleDown')} onClick={goToFirstPage}>
          {page !== 1 ? (
            <>
              <img alt='' src={iconNextDownWhite} />
              <img alt='' src={iconNextDownWhite} />
            </>
          ) : (
            <>
              <img alt='' src={iconNextDown} />
              <img alt='' src={iconNextDown} />
            </>
          )}
        </button>
        <button className={page !== 1 ? cx('pnDownMax') : cx('pnDown')} onClick={goToPreviousPage}>
          {page !== 1 ? (
            <>
              <img alt='' src={iconNextDownWhite} />
            </>
          ) : (
            <>
              <img alt='' src={iconNextDown} />
            </>
          )}
        </button>
        <h5 className={cx('pnTransfer')}>
          Page {page ? page : 1} / {totalPage === 0 ? 1 : totalPage}
        </h5>
        <button className={page !== totalPage ? cx('pnUpMax') : cx('pnUp')} onClick={nextPage}>
          {page !== totalPage ? (
            <>
              <img alt='' src={iconNextUpWhite} />
            </>
          ) : (
            <>
              <img alt='' src={iconNextUp} />
            </>
          )}
        </button>
        <button className={page !== totalPage ? cx('pnDoubleUpMax') : cx('pnDoubleUp')} onClick={goToEndPage}>
          {page !== totalPage ? (
            <>
              <img alt='' src={iconNextUpWhite} />
              <img alt='' src={iconNextUpWhite} />
            </>
          ) : (
            <>
              <img alt='' src={iconNextUp} />
              <img alt='' src={iconNextUp} />
            </>
          )}
        </button>
      </div>
    </div>
  ) : null;
};

export default PagiNation;
