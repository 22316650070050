//Library
import React from 'react';
import classNames from 'classnames/bind';
import { Outlet } from 'react-router-dom';
//Components
//Feature
//Utils
//Icon
//Scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const UserManagement = () => {
  return (
    <div id='idUserManagement' className={cx('wrapper')}>
      <Outlet />
    </div>
  );
};

export default UserManagement;
