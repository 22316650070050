export const baseURL = process.env.REACT_APP_BASE_URL;
export const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
export const socketURL = process.env.REACT_APP_SOCKET_URL;

export const EventType = {
  EVENT_CONNECTION: 'connection',
  EVENT_CONNECT_ERROR: 'connect_error',
  EVENT_DISCONNECT: 'disconnect',
  EVENT_DISCONNECTING: 'disconnecting',
};

export const EventHousehold = {
  CONNECT_HOUSEHOLD: 'CONNECT_HOUSEHOLD',
  HOUSEHOLD: 'HOUSEHOLD',
};

export const authUrl = {
  auth: 'auth',
  login: 'login',
  forgotPassword: 'forgot-password',
  createNewPassword: 'create-new-password',
};

//#region Endpoint Api
export const serviceUrl = {
  getAllServiceWithoutParams: 'service-groups/groups',
  getAllService: 'service-groups',
  postService: 'service-groups',
  deleteService: 'service-groups',
  putService: 'service-groups',
};

export const subServiceUrl = {
  getAllSubService: 'services',
  postSubService: 'v2/services',
  deleteSubService: 'services',
  putSubService: 'v2/services',
};

export const combineServiceUrl = {
  basic: '/offers',
  getAllCombineService: '/offers/all',
};

export const householdUrl = {
  getAllHouseHold: 'households',
};

export const requestUrl = {
  basic: '/requests',
  getAllRequest: 'requests',
  calendarAvailable: 'calendar-available',
};

export const userManagementApi = {
  getUserManagement: 'staffs',
};
//#endregion Endpoint Api

export const provinces = ['PE', 'NS', 'NB', 'QC', 'ON', 'MB', 'SK', 'AB', 'BC', 'YT'];

// Regex
export const POSTAL_CODE_CANADA = /^[a-zA-Z]\d[a-zA-Z][ -]?\d[a-zA-Z]\d$/i;
export const PHONE_NUMBER_CANADA = /^(?=.*\d).{12,}$/;
// export const POSTAL_CODE_CANADA = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
export const TEXT_REGEX = /^[a-zA-Z]*$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const NUMBER_REGEX = /[^\d]/g;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/;
// export const OFFER_PERCENT_REGEX = /^[1-9][0-9]?$|^100$/;
export const OFFER_PERCENT_REGEX = /^[0-9]*[1-9][0-9]*$/;
export const OFFER_PERCENT_REGEX1 = /^[0-9]*[.]*[0-9]*$/;

export const TIME_OPTIONS = [
  '08:00 AM - 10:00 AM',
  '08:15 AM - 10:15 AM',
  '08:30 AM - 10:30 AM',
  '08:45 AM - 10:45 AM',
  '09:00 AM - 11:00 AM',
];
