export const routerPaths = {
  //Role
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',

  //Function Component
  CREATE: 'create',
  LIST: 'list',
  EDIT: ':id',
  LIST_HOUSE: 'list-household',
  LIST_ORDERS: 'list-orders',
  LIST_TOW: 'list-tow',
  //Super Admin Menu
  SERVICES: 'services',
  SUB_SERVICES: 'sub-services',
  COMBINE_SERVICES: 'combine-services',
  HOUSEHOLDS: 'households',
  USERS: 'users',
  ORDERS: 'orders',

  // Auth
  AUTH: 'auth',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  CHECK_MAIL: 'check-mail',
  CREATE_NEW_PASSWORD: 'create-new-password',
  REGISTER: 'register',
};

export const exactRouter = {
  // Auth
  signIn: `/${routerPaths.AUTH}/${routerPaths.LOGIN}`,
  auth: `/${routerPaths.AUTH}`,
  forgotPassword: `/${routerPaths.AUTH}/${routerPaths.FORGOT_PASSWORD}`,
  checkMail: `/${routerPaths.AUTH}/${routerPaths.CHECK_MAIL}`,

  // SuperAdmin
  superAdmin: {
    // Service
    listService: `/${routerPaths.SUPER_ADMIN}/${routerPaths.SERVICES}/${routerPaths.LIST}`,
    createService: `/${routerPaths.SUPER_ADMIN}/${routerPaths.SERVICES}/${routerPaths.CREATE}`,
    editService: `/${routerPaths.SUPER_ADMIN}/${routerPaths.SERVICES}`,
    listTowService: `/${routerPaths.SUPER_ADMIN}/${routerPaths.SERVICES}/${routerPaths.LIST_TOW}`,

    // Sub Service
    listSubService: `/${routerPaths.SUPER_ADMIN}/${routerPaths.SUB_SERVICES}/${routerPaths.LIST}`,
    createSubService: `/${routerPaths.SUPER_ADMIN}/${routerPaths.SUB_SERVICES}/${routerPaths.CREATE}`,
    editSubService: `/${routerPaths.SUPER_ADMIN}/${routerPaths.SUB_SERVICES}`,
    listTowSubService: `/${routerPaths.SUPER_ADMIN}/${routerPaths.SUB_SERVICES}/${routerPaths.LIST_TOW}`,

    // CombineService
    combineService: `/${routerPaths.SUPER_ADMIN}/${routerPaths.COMBINE_SERVICES}`,
    listCombineService: `/${routerPaths.SUPER_ADMIN}/${routerPaths.COMBINE_SERVICES}/${routerPaths.LIST}`,
    createCombineService: `/${routerPaths.SUPER_ADMIN}/${routerPaths.COMBINE_SERVICES}/${routerPaths.CREATE}`,

    //House Hold
    houseHolds: `/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}`,
    listHouseholds: `/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}/${routerPaths.LIST_HOUSE}`,
    listRequests: `/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}/${routerPaths.LIST_REQUESTS}`,
    createHouseholds: `/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}/${routerPaths.CREATE}`,
    editHouseholds: `/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}`,

    // User
    listUser: `/${routerPaths.SUPER_ADMIN}/${routerPaths.USERS}/${routerPaths.LIST}`,

    // Orders
    orders: `/${routerPaths.SUPER_ADMIN}/${routerPaths.ORDERS}`,
    listOrders: `/${routerPaths.SUPER_ADMIN}/${routerPaths.ORDERS}/${routerPaths.LIST_ORDERS}`,
  },

  // Admin
  admin: {
    //House Hold
    houseHolds: `/${routerPaths.ADMIN}/${routerPaths.HOUSEHOLDS}`,
    listHouseholds: `/${routerPaths.ADMIN}/${routerPaths.HOUSEHOLDS}/${routerPaths.LIST_HOUSE}`,
    listOrders: `/${routerPaths.ADMIN}/${routerPaths.ORDERS}/${routerPaths.LIST_ORDERS}`,
    createHouseholds: `/${routerPaths.ADMIN}/${routerPaths.HOUSEHOLDS}/${routerPaths.CREATE}`,
    editHouseholds: `/${routerPaths.ADMIN}/${routerPaths.HOUSEHOLDS}`,
    orders: `/${routerPaths.ADMIN}/${routerPaths.ORDERS}`,
  },
};
