import classNames from 'classnames/bind';
import { useState, useEffect } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
// import component
import Search from 'src/components/common/search';
import Filter from 'src/components/common/filter/Filter';
import CreateSubService from '../create';

// import component common
import TableHeader from './table/tableHeaderSercive/TableHeader';
import TableItem from './table/tableItem/tableItem';
import TableHeaderMob from './table/tablemob/Tablemode';
import PagiNation from 'src/components/common/pagination/Pagination';
import NoDataAvailable from 'src/components/common/noDataAvailable/NoDataAvaliable';
//
import { digLogAction } from 'src/features/digLog/slice';
import {
  selectSubServiceFetchDataAfterAction,
  selectSubServiceFilter,
  selectSubServiceList,
  selectSubServiceMessage,
  selectSubServicePaginationTotal,
  selectSubServiceShowAdd,
  selectSubServiceShowMessage,
  selectSubServiceTotal,
  selectSubServiceTypeAction,
  subServiceActions,
} from 'src/features/superAdmin/subService/slice';
import { selectServiceList, selectServiceListAll, serviceActions } from 'src/features/superAdmin/service/slice';

//utils
import { subServiceFilter } from 'src/utils/enum';
import { contentDialog } from 'src/utils/contentDialog';
import { convertArrToObjectOptions, handleErrorMessages } from 'src/utils/help';

//scss
import styles from './styles.module.scss';
const cx = classNames.bind(styles);

const ListSubService = () => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(subServiceFilter.values.All);
  const [selectedServiceFilterOption, setSelectedServiceFilterOption] = useState('ALL');
  const [showSidebar, setShowSidebar] = useState(false);

  /**
   * UseSelector Block
   */
  const listService = useSelector(selectServiceListAll);
  const listSubService = useSelector(selectSubServiceList);
  const total = useSelector(selectSubServiceTotal);
  const isShowMessage = useSelector(selectSubServiceShowMessage);
  const typeAction = useSelector(selectSubServiceTypeAction);
  const filter = useSelector(selectSubServiceFilter);
  const addShow = useSelector(selectSubServiceShowAdd);
  const paginationTotal = useSelector(selectSubServicePaginationTotal);
  const message = useSelector(selectSubServiceMessage);
  const fetchDataAfterAction = useSelector(selectSubServiceFetchDataAfterAction);

  const optionsServices = convertArrToObjectOptions(listService);

  const handleShow = () => {
    setShowSidebar(true);
  };

  const handleChangeFilterByStatus = (index) => {
    dispatch(subServiceActions.filter({ ...filter, isActive: subServiceFilter.keys[index], page: 1 }));
  };

  const handleChangeFilterByService = (index) => {
    dispatch(subServiceActions.filter({ ...filter, serviceGroupId: index === 'ALL' ? '' : index, page: 1 }));
  };

  const handleSearch = (values) => {
    dispatch(subServiceActions.filter({ ...filter, search: values, page: 1 }));
  };

  const handlePageChange = (values) => {
    dispatch(subServiceActions.filter(values));
  };

  /**
   * UseEffect Block
   */

  useEffect(() => {
    dispatch(subServiceActions.resetState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(serviceActions.getAllServiceWithoutParams());
  }, [dispatch]);

  useEffect(() => {
    dispatch(subServiceActions.getAllSubService(filter));
  }, [dispatch, filter]);

  // Handle show modal
  useEffect(() => {
    if (isShowMessage === true && typeAction === 'CREATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.CREATE_NEW_SUBSERVICE.SUCCESS,
          function: () => {
            dispatch(subServiceActions.resetStatus());
            dispatch(subServiceActions.getAllSubService(filter));
          },
        })
      );
    }
    if (isShowMessage === true && typeAction === 'DELETE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.DELETE_SUBSERVICE.SUCCESS,
          function: () => {
            dispatch(subServiceActions.resetStatus());
            dispatch(subServiceActions.getAllSubService(filter));
          },
        })
      );
    }
    if (isShowMessage === true && typeAction === 'UPDATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.UPDATE_SUBSERVICE.SUCCESS,
          function: () => {
            dispatch(subServiceActions.resetStatus());
            dispatch(subServiceActions.getAllSubService(filter));
          },
        })
      );
    }
    if (isShowMessage === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(subServiceActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, message, isShowMessage, typeAction]);

  useEffect(() => {
    if (addShow === true) {
      setShowSidebar(false);
      dispatch(subServiceActions.cloneAdd());
    }
  }, [addShow, dispatch]);

  return (
    <>
      <div className={cx('lService')}>
        <p className={cx('lServiceName')}>Sub Services: {total > 0 ? total : 0}</p>
        <button className={cx('lBtnAddService')} onClick={handleShow}>
          <p>+</p>
          <div>Add New Sub Service</div>
        </button>
      </div>

      <div className={cx('lBlock')}>
        <div className={cx('lFilters')}>
          <div className={cx('lFilters')}>
            <Filter
              filter={{ ALL: 'All services', ...optionsServices }}
              selected={selectedServiceFilterOption}
              setSelected={setSelectedServiceFilterOption}
              handleChangeFilter={handleChangeFilterByService}
            />
            <Filter
              filter={subServiceFilter.values}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={handleChangeFilterByStatus}
            />
          </div>
          <Search handleSearch={handleSearch} placeholder={'Search by Service'} />
        </div>
        <div className={cx('lItemDesktop')}>
          <TableHeader />
          <div className={cx('lItemWrapper')}>
            {paginationTotal ? (
              listSubService.map((value, i) => <TableItem key={i} {...value} delete={true} />)
            ) : (
              <NoDataAvailable />
            )}
          </div>
        </div>
        <div>
          {paginationTotal > 0 ? (
            <div className={cx('lItemMob')}>
              {listSubService.map((value, i) => (
                <TableHeaderMob key={i} {...value} />
              ))}
            </div>
          ) : (
            <NoDataAvailable />
          )}
        </div>
        <div className={cx('lIPagination')}>
          {paginationTotal > 0 ? (
            <PagiNation filter={filter} total={paginationTotal} handlePageChange={handlePageChange} />
          ) : null}
        </div>
      </div>

      <CreateSubService showSubService={showSidebar} setShowSidebar={setShowSidebar} />
    </>
  );
};

export default ListSubService;
