import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  isSuccess: null,
  message: '',
  isShowMessage: null,
  typeAction: '',

  listCombineService: [],
  total: 0,
  pagination: {
    page: 1,
    limit: 10,
    total: 0,
  },
};

const combineServiceSlice = createSlice({
  name: 'combineService',
  initialState,
  reducers: {
    resetStatus(state) {
      state.isDelete = null;
      state.message = '';
      state.isSuccess = null;
      state.isShowMessage = null;
      state.typeAction = '';
    },
    // GET COMBINE SERVICE
    getAllCombineService(state, action) {
      state.loading = true;
      state.isSuccess = false;
    },
    getAllCombineServiceSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.total = action.payload.total;
      state.pagination = action.payload.pagination;
      state.listCombineService = action.payload.offers;
    },
    getAllCombineServiceFail(state, action) {
      state.loading = false;
    },

    // CREATE COMBINE SERVICE
    createCombineService(state, action) {
      state.loading = true;
    },
    createCombineServiceSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.isShowMessage = true;
      state.typeAction = 'CREATE';
    },
    createCombineServiceFail(state, action) {
      state.loading = false;
      state.isSuccess = false;
      state.message = action.payload;
      state.isShowMessage = false;
      state.typeAction = '';
    },

    // UPDATE COMBINE SERVICE
    updateCombineService(state, action) {
      state.loading = true;
      state.isSuccess = false;
    },
    updateCombineServiceSuccess(state, action) {
      state.loading = false;
      state.message = action.payload;
      state.isSuccess = true;
      state.isShowMessage = true;
      state.typeAction = 'UPDATE';
    },
    updateCombineServiceFail(state, action) {
      state.loading = false;
      state.isSuccess = false;
      state.message = action.payload;
      state.isShowMessage = false;
      state.typeAction = '';
    },

    // DELETE COMBINE SERVICE
    deleteCombineService(state, action) {
      state.loading = true;
      state.isSuccess = false;
    },
    deleteCombineServiceSuccess(state, action) {
      state.loading = false;
      state.message = action.payload;
      state.isSuccess = true;
      state.isShowMessage = true;
      state.typeAction = 'DELETE';
    },
    deleteCombineServiceFailed(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.message = action.payload;
      state.isShowMessage = false;
      state.typeAction = '';
    },

    // ACTION PAGINATION
    setPagination(state, action) {
      state.pagination = action.payload.pagination;
    },
    resetState: () => initialState,
  },
});

export const combineServiceActions = combineServiceSlice.actions;

export const selectCombineServiceLoading = (state) => state.combineService.loading;
export const selectCombineServiceList = (state) => state.combineService.listCombineService;
export const selectCombineServiceTotal = (state) => state.combineService.total;
export const selectCombineServicePagination = (state) => state.combineService.pagination;
export const selectCombineServiceIsSuccess = (state) => state.combineService.isSuccess;
export const selectCombineServiceMessage = (state) => state.combineService.message;
export const selectCombineServiceTypeAction = (state) => state.combineService.typeAction;
export const selectCombineServiceShowMessage = (state) => state.combineService.isShowMessage;

const combineServiceReducer = combineServiceSlice.reducer;
export default combineServiceReducer;
