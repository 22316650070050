import React, { useState } from 'react';
import classNames from 'classnames/bind';
import Sidebar from 'src/layouts/superAdmin/sidebar';
import Header from 'src/layouts/components/header';
import styles from './styles.module.scss';
import { Outlet } from 'react-router-dom';

const cx = classNames.bind(styles);

const SuperAdminLayout = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <div className={cx('wrapper')}>
      <Sidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div className={cx('container')}>
        <div className={cx('header')}>
          <Header />
        </div>
        <div className={cx('content')}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminLayout;
