import { subServiceUrl } from 'src/utils/constants';
import axiosClient from '../axiosClient';

const subServiceApi = {
  getAllSubService(params) {
    const url = `/${subServiceUrl.getAllSubService}`;
    return axiosClient.get(url, { params });
  },
  postSubService(data) {
    const url = `/${subServiceUrl.postSubService}`;
    return axiosClient.post(url, data);
  },
  deleteSubService(id) {
    const url = `/${subServiceUrl.deleteSubService}/${id}`;
    return axiosClient.delete(url);
  },
  updateSubService(data) {
    const url = `/${subServiceUrl.putSubService}/${data.id}`;

    const newData = {
      isActive: data.isActive,
      logo: data.logo,
      name: data.name,
      serviceGroupId: data.serviceGroupId,
      typeServices: data.typeServices,
      note: data?.note,
      emptySlot1: data?.emptySlot1,
      emptySlot2: data?.emptySlot2,
      emptySlot3: data?.emptySlot3,
      emptySlot4: data?.emptySlot4,
      emptySlot5: data?.emptySlot5,
    };
    return axiosClient.put(url, newData);
  },
};

export default subServiceApi;
