import { EventHousehold, socketURL } from 'src/utils/constants';

export const connectHousehold = (ws) => {
  ws.onopen = () => {
    console.log(`[WSS][connectHousehold] WebSocket Connected With Url ${JSON.stringify(socketURL)}`);
    ws.send(JSON.stringify({ type: EventHousehold.CONNECT_HOUSEHOLD }));
  };

  ws.onerror = (event) => {
    console.error('[WSS][connectHousehold] WebSocket error observed:', event);
  };
};
