import React from 'react';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'devextreme-react';

//component
import PagiNation from 'src/components/common/pagination/Pagination';
import Filter from 'src/components/common/filter/Filter';
import Search from 'src/components/common/search';
import {
  houseActions,
  selectHouseholdFilter,
  selectHouseholdOpenForm,
  selectHouseholdTotal,
  selectHouseholdIsSuccess,
  selectHouseholdList,
  selectHouseholdShowMessage,
  selectHouseholdTypeAction,
  selectHouseholdMessage,
} from 'src/features/superAdmin/household/slice';
import { selectAuthRole } from 'src/features/auth/authSlice';
//utils
import { CheckRoleUser, houseHoldFilter } from 'src/utils/enum';
import { exactRouter } from 'src/utils/routers';
import { EventHousehold, socketURL } from 'src/utils/constants';
//Component
import CreateHouseHold from '../../create';
import TableHeaderHouseHold from './table/tableHeader/TableHeader';
import TableItemHouseHold from './table/tableItem/TableItem';
import TableCardMob from './table/tablecardmob/TableCardMob';
//scss
import styles from './styles.module.scss';
import NoDataAvailable from 'src/components/common/noDataAvailable/NoDataAvaliable';
import { digLogAction } from 'src/features/digLog/slice';
import { contentDialog } from 'src/utils/contentDialog';
import { handleErrorMessages } from 'src/utils/help';

/** Services */
import { connectHousehold, handleHousehold } from 'src/services/household';

const cx = classNames.bind(styles);
// const client = io.connect(socketURL);

const ListHouse = () => {
  console.log(`[ListHouse]: render`);

  // Util
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selector
  const households = useSelector(selectHouseholdList);
  const filterHouse = useSelector(selectHouseholdFilter);
  const showAdd = useSelector(selectHouseholdOpenForm);
  const total = useSelector(selectHouseholdTotal);
  const isShowMessage = useSelector(selectHouseholdShowMessage);
  const typeAction = useSelector(selectHouseholdTypeAction);
  const selectIsSuccess = useSelector(selectHouseholdIsSuccess);
  const message = useSelector(selectHouseholdMessage);
  const role = useSelector(selectAuthRole);

  const [showHouse, setShowHouse] = useState(false);
  const [selected, setSelected] = useState(houseHoldFilter.values.All);
  const [listEdit, setListEdit] = useState(undefined);
  const [ws, setWs] = useState(new WebSocket(socketURL));

  const handleSearch = (values) => {
    dispatch(houseActions.filterHousehold({ ...filterHouse, textSearch: values, page: 1 }));
    dispatch(houseActions.getAllHousehold({ ...filterHouse, textSearch: values, page: 1 }));
  };

  const handleChangeFilter = (idx) => {
    dispatch(houseActions.filterHousehold({ ...filterHouse, houseSize: houseHoldFilter.keys[idx], page: 1 }));
    dispatch(houseActions.getAllHousehold({ ...filterHouse, houseSize: houseHoldFilter.keys[idx], page: 1 }));
  };

  const handlePageChange = (values) => {
    dispatch(houseActions.filterHousehold(values));
    dispatch(houseActions.getAllHousehold(values));
  };

  /**
   * UseEffect Block
   */
  /** Handle useEffect show dialog */
  useEffect(() => {
    if (isShowMessage === true && typeAction === 'CREATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.CREATE_HOUSEHOLD.SUCCESS,
          function: () => {
            dispatch(houseActions.resetStatus());
            dispatch(houseActions.getAllHousehold(filterHouse));
          },
        })
      );
    } else if (isShowMessage === true && typeAction === 'UPDATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.UPDATE_HOUSEHOLD.SUCCESS,
          function: () => {
            dispatch(houseActions.resetStatus());
            dispatch(houseActions.getAllHousehold(filterHouse));
          },
        })
      );
    } else if (isShowMessage === true && typeAction === 'DELETE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.DELETE_HOUSEHOLD.SUCCESS,
          function: () => {
            dispatch(houseActions.resetStatus());
            dispatch(houseActions.getAllHousehold(filterHouse));
          },
        })
      );
    } else if (isShowMessage === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(houseActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, filterHouse, isShowMessage, message, typeAction]);

  // Handle When render component first time
  useEffect(() => {
    connectHousehold(ws);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(`[WSS][ListHouse] data from websocket -> ${JSON.stringify(data)}`);

      switch (data.type) {
        case EventHousehold.HOUSEHOLD:
          console.log(`[WSS][ListHouse] Type event -> ${data.type}`);

          console.log(`[WSS][ListHouse] dispatch -> getAllHousehold`);
          dispatch(houseActions.getAllHousehold({ isSocket: true, ...filterHouse }));

          break;
        default:
          break;
      }
    };

    ws.onclose = () => {
      console.log(`[WSS][ListHouse] WebSocket Close Connect`);
      setWs(new WebSocket(socketURL));
    };
  }, [ws.onclose, ws.onerror]);

  useEffect(() => {
    console.log(`[ListHouse][useEffect] dispatch -> houseActions.getAllHousehold`);
    dispatch(houseActions.getAllHousehold(filterHouse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleAddNewHousehold = () => {
    console.log(`[ListHouse][handleAddNewHousehold] setShowHouse`);
    setShowHouse(true);
  };

  useEffect(() => {
    if (showAdd === true) {
      console.log(`[ListHouse][useEffect] dispatch -> houseActions.cloneForm`);
      dispatch(houseActions.cloneForm());
    }
  }, [dispatch, showAdd]);

  return (
    <>
      <CreateHouseHold
        setShowHouse={setShowHouse}
        showHouse={showHouse}
        listEdit={listEdit}
        setListEdit={setListEdit}
      />
      <div className={cx('lsTitle')}>
        <p className={cx('lsHouseHoldsName')}>Households: {total || '0'}</p>
        <button className={cx('lBtnAddHouseHold')} onClick={handleAddNewHousehold}>
          <p className={cx('lPlus')}>+</p>Add New Household
        </button>
      </div>
      {/* <div className={cx('hhTabs')}>
        <Button
          text='Households'
          activeStateEnabled={false}
          focusStateEnabled={false}
          className={cx('lBntTabHousehold')}
          onClick={() => {
            if (role === CheckRoleUser.SUPER_ADMIN) {
              navigate(`${exactRouter.superAdmin.listHouseholds}`);
            } else if (role === CheckRoleUser.ADMIN) {
              navigate(`${exactRouter.admin.listHouseholds}`);
            }
          }}
        />
        <Button
          text='Requests'
          activeStateEnabled={false}
          focusStateEnabled={false}
          className={cx('lBntTab')}
          onClick={() => {
            if (role === CheckRoleUser.SUPER_ADMIN) {
              navigate(`${exactRouter.superAdmin.listRequests}`);
            } else if (role === CheckRoleUser.ADMIN) {
              navigate(`${exactRouter.admin.listRequests}`);
            }
          }}
        />
      </div> */}

      <div className={cx('lWrapper')}>
        <div className={cx('lFilters')}>
          <Filter
            filter={houseHoldFilter.values}
            selected={selected}
            setSelected={setSelected}
            handleChangeFilter={handleChangeFilter}
          />
          <Search handleSearch={handleSearch} placeholder='Search by Household Name' />
        </div>
        <div className={cx('lCardDesktop')}>
          <TableHeaderHouseHold />
          {filterHouse.total !== 0 ? (
            <div className={cx('lWrapItem')}>
              {households.map((item, i) => (
                <TableItemHouseHold
                  key={i}
                  household={item}
                  setListEdit={setListEdit}
                  showHouse={showHouse}
                  setShowHouse={setShowHouse}
                />
              ))}
            </div>
          ) : (
            <NoDataAvailable />
          )}
        </div>

        <div className={cx('Cardnodedata')}>
          {filterHouse.total !== 0 ? (
            <div className={cx('lCard')}>
              {households.map((item, i) => (
                <TableCardMob
                  key={i}
                  showHouse={showHouse}
                  setShowHouse={setShowHouse}
                  setListEdit={setListEdit}
                  household={item}
                />
              ))}
            </div>
          ) : (
            <NoDataAvailable />
          )}
        </div>

        {filterHouse.total !== 0 ? (
          <div className={cx('lPagination')}>
            <div className={cx('lLegend')}>
              <div className={cx('lLegendItem')}>
                <div className={cx('lConnectedStatus')}></div>
                <span>Connected</span>
              </div>

              <div className={cx('lLegendItem')}>
                <div className={cx('lDisconnectedStatus')}></div>
                <span>Disconnected</span>
              </div>

              <div className={cx('lLegendItem')}>
                <div className={cx('lNotConnectStatus')}></div>
                <span>Not connect</span>
              </div>
            </div>
            <PagiNation filter={filterHouse} total={filterHouse.total} handlePageChange={handlePageChange} />{' '}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ListHouse;
