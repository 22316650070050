//Library
import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
//Components
//Feature
import { userManagementAction } from 'src/features/superAdmin/userManagement/slice';
import { digLogAction } from 'src/features/digLog/slice';
//Utils
import { capitalizeFirstLetter } from 'src/utils/help';
import { contentDialog } from 'src/utils/contentDialog';
//Icon
import iconEdit from 'src/assets/icons/ic_edit.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';
import iconDeleteBox from 'src/assets/icons/ic_delete_box.svg';
import iconStatus from 'src/assets/icons/ic_check_box.svg';
//Image
import userImage from 'src/assets/images/img_avatar.jpg';
//Scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const TableItemUserTab = (props) => {
  const dispatch = useDispatch();
  const { _id, name, email, gender, phoneNumber, role, isActive, avatar, setEdit, handleShow } = props;

  const deleteUser = () => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE.CLICK,
        function: () => {
          dispatch(userManagementAction.deleteUser(_id));
        },
        functionCancel: () => {},
      })
    );
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('flex')}>
        <div
          className={cx('icon-edit')}
          onClick={() => {
            setEdit({
              avatar: avatar,
              name: name?.firstName,
              lastName: name?.lastName,
              gender: gender,
              phoneNumber: {
                countryCode: phoneNumber?.countryCode,
                phone: phoneNumber?.phone,
              },
              role: role,
              status: isActive,
              _id: _id,
            });
            handleShow();
          }}
        >
          <img src={iconEdit} className={cx('margin')} alt='icon' />
        </div>
        <div className={cx('icon-delete')} onClick={deleteUser}>
          <img src={iconDelete} className={cx('margin')} alt='icon' />
        </div>
      </div>
      <div className={cx('imgWrapper')}>
        <img
          src={avatar || userImage}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = userImage;
          }}
          alt=''
          className={cx('img')}
        />
      </div>
      <div>
        {/* <div className={cx('itemTable')}>
          <div>User</div>
          <div className={cx('itContent')}> {`${name?.firstName} ${name?.lastName} `}</div>
        </div> */}
        <div className={cx('itemTable')}>
          <div>Email</div>
          <div className={cx('itContent')}>{email}</div>
        </div>
        <div className={cx('itemTable')}>
          <div>Gender</div>
          <div>{capitalizeFirstLetter(gender)}</div>
        </div>
        <div className={cx('itemTable')}>
          <div>Phone</div>
          <div>
            {/* {phoneNumber?.countryCode} {''} */}
            {phoneNumber?.phone}
          </div>
        </div>
        <div className={cx('itemTable')}>
          <div>Role</div>
          <div>{capitalizeFirstLetter(role)}</div>
        </div>
        <div className={cx('itemTable')}>
          <div>Status</div>

          <div className={isActive ? cx('status') : cx('notStatus')}>
            {isActive ? (
              <img src={iconStatus} className={cx('icon')} alt='icon' />
            ) : (
              <img src={iconDeleteBox} className={cx('iconBox')} alt='icon' />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableItemUserTab;
