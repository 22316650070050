import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames/bind';

import Dropdown from 'src/components/dropdown';
import InputField from 'src/components/inputField';

import {
  selectUserIsSuccess,
  selectUserLoading,
  selectUserShowMessage,
  userManagementAction,
} from 'src/features/superAdmin/userManagement/slice';

import { genderUser, roleUser, statusUser } from 'src/utils/enum';
import { formatPhoneNumber, splitName } from 'src/utils/help';
import { EMAIL_REGEX, PHONE_NUMBER_CANADA } from 'src/utils/constants';

import iconClose from 'src/assets/icons/ic_close.svg';
import imgAvatar from 'src/assets/images/img_avatar.jpg';
import iconUpdate from 'src/assets/icons/ic_edit_logo.svg';

import styles from './styles.module.scss';
import { digLogAction } from 'src/features/digLog/slice';

const cx = classNames.bind(styles);

const CreateUser = (props) => {
  const dispatch = useDispatch();
  /**
   * useSelector Block
   */
  const checkReset = useSelector(selectUserIsSuccess);
  /**
   * props
   */
  const { setShow, show, edit, setEdit } = props;
  /**
   * state
   */

  const formEdit = edit && {
    avatar: edit.avatar,
    // name: edit.name,
    // lastName: edit.lastName,
    gender: edit.gender,
    countryCode: edit.phoneNumber.countryCode,
    phone: edit.phoneNumber.phone,
    role: edit.role,
    status: edit.status,
  };
  const [fileUrl, setFileUrl] = useState(edit?.avatar || '');
  const [gender, setGender] = useState(null);
  const [role, setRole] = useState();
  const [status, setStatus] = useState();
  const [phone, setPhone] = useState('');
  const [showDropdown, setShowDropdown] = useState([]);
  const [imgFile, setImgFile] = useState('');
  const [up, setUp] = useState(false);

  const loading = useSelector(selectUserLoading);
  const isShowMessage = useSelector(selectUserShowMessage);

  /**
   * hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  /**
   * handle
   */
  const processImage = (e) => {
    const file = e.target.files[0];

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      if (!edit?.avatar) {
        dispatch(digLogAction.getTitle({ title: 'Incorrect Image Format' }));
      }

      return false;
    }

    const imageUrl = URL.createObjectURL(file);
    setFileUrl(imageUrl);

    let fromData = new FormData();

    fromData.append('file', file);

    setImgFile(fromData);
  };

  const handleClose = () => {
    reset();
    setPhone();
    setRole();
    setStatus();
    setGender();
    setFileUrl('');
    setShow(false);
    setUp(!up);
  };

  const createUser = (data) => {
    console.log(`[CreateService] data -> ${JSON.stringify(data, null, 2)}`);
    // if (!fileUrl) {
    //   if (!edit?.avatar) {
    //     dispatch(digLogAction.getTitle({ title: 'Can not leave the picture blank' }));
    //     return false;
    //   }
    // }

    const formData = {
      // name: splitName(data.name),
      // name: {
      //   firstName: data.name,
      //   lastName: data.lastName,
      // },
      email: data.email,
      gender: `${gender ? gender : 'MALE'}`,
      phoneNumber: {
        countryCode: '+1',
        phone: data.phone,
      },
      role: role ? role : edit?.role ? roleUser.values[role] : 'ADMIN',
      isActive: status === 'ACTIVE' ? true : false,
      _id: edit !== undefined ? edit._id : null,
    };

    if (edit === undefined) {
      dispatch(userManagementAction.createUser({ formData: formData, fileUrl: imgFile }));
    } else {
      dispatch(
        userManagementAction.updateUser({ formData: formData, fileUrl: imgFile || 1, updateImage: edit?.avatar })
      );
    }
  };

  useEffect(() => {
    if (!show) {
      reset({});
      setEdit();
      setPhone();
      setRole();
      setStatus();
      setGender();
      setFileUrl('');
      setUp(!up);
    }
  }, [show]);

  useEffect(() => {
    const arr = [...Array(3)].map((value) => false);
    setShowDropdown(arr);
  }, []);

  const handleDropdown = (index) => {
    const arr = [...Array(3)].map((value) => false);
    arr[index] = true;
    setShowDropdown(arr);
  };

  useEffect(() => {
    if (edit) {
      setStatus(edit.status === true ? 'ACTIVE' : 'INACTIVE');
      setRole(edit.role.toUpperCase());
      setGender(edit.gender.toUpperCase());
    }
  }, [edit, up]);

  useEffect(() => {
    if (formEdit) {
      reset(formEdit);
      setPhone(formEdit?.phone);
    }
  }, [edit]);

  useEffect(() => {
    if (checkReset === true) {
      reset();
    }
  }, [checkReset]);
  return (
    <div id='createUser'>
      <div className={`${cx('crUserContainer')} ${show ? cx('crUserContainerShow') : cx('')}`}>
        <div className={cx('crContent')}>
          <div className={cx('crClose')}>
            <div className={cx('crBtnClose')} onClick={handleClose}>
              <img src={iconClose} alt='close' />
            </div>
          </div>
          <div className={cx('crFormWrap')}>
            {edit === undefined ? (
              <span className={cx('crTitle')}>Create New User</span>
            ) : (
              <span className={cx('crTitle')}>Edit User</span>
            )}
            <div className={cx('crWrap')}>
              <div className={cx('crWrapAvatar')}>
                <img src={fileUrl || edit?.avatar || imgAvatar} alt='Avatar' className={cx('crLogo')} />
                <div className={cx('crEdit')}>
                  <input type='file' className={cx('crInputFile')} value='' onChange={processImage} />
                  <img src={iconUpdate} alt='' className={cx('crIconEdit')} />
                </div>
              </div>
            </div>
            <form className={cx('crForm')} onSubmit={handleSubmit(createUser)}>
              {/* <InputField
                classNameWrap={cx('crFormInput')}
                label={'First Name*'}
                classNameLabel={cx('crTitleInput')}
                register={register}
                name={'name'}
                type={'text'}
                classNameInput={cx('crValueInput')}
                validation={{
                  required: 'User name is required',
                }}
                error={errors['name']}
              />
              <InputField
                classNameWrap={cx('crFormInput')}
                label={'Last Name*'}
                classNameLabel={cx('crTitleInput')}
                register={register}
                name={'lastName'}
                type={'text'}
                classNameInput={cx('crValueInput')}
                validation={{
                  required: 'User last is required',
                }}
                error={errors['lastName']}
              /> */}
              {edit === undefined ? (
                <InputField
                  classNameWrap={cx('crFormInput')}
                  label={'Email*'}
                  classNameLabel={cx('crTitleInput')}
                  register={register}
                  name={'email'}
                  type={'text'}
                  classNameInput={`${cx('crValueInput')} ${cx('crValueEmail')}`}
                  validation={{
                    required: 'Email is required',
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Email format is not valid',
                    },
                  }}
                  onChange={(e) => setValue('email', e.target.value)}
                  error={errors['email']}
                />
              ) : (
                <></>
              )}
              <div className={cx('crFormInput')}>
                <label htmlFor='input' className={cx('crTitleInput')}>
                  Gender
                </label>
                <Dropdown
                  register={register}
                  name={'gender'}
                  selected={genderUser.values}
                  options={gender}
                  setOptions={setGender}
                  valueUpdate={edit?.gender}
                  show={showDropdown[0]}
                  index={0}
                  setshow={handleDropdown}
                  up={up}
                />
              </div>
              <div className={cx('crPhone')}>
                <label htmlFor='input' className={cx('crTitleInput')}>
                  Phone Number*
                </label>
                <div style={{ display: 'flex' }}>
                  {/* <InputField
                    classNameWrap={cx('')}
                    classNameLabel={'crTitleInput'}
                    register={register}
                    name={'countryCode'}
                    type={'text'}
                    placeholder={'+1'}
                    readOnly
                    classNameInput={cx('crCountryCode')}
                  /> */}

                  <InputField
                    classNameWrap={cx('crFormInput')}
                    classNameLabel={'crTitleInput'}
                    register={register}
                    name={'phone'}
                    type={'text'}
                    max={12}
                    classNameInput={cx('crValueInput')}
                    placeholder={'000-000-0000'}
                    // onChange={(e) => setPhone(formatPhoneNumber(e.target.value))}
                    // value={phone || ''}
                    onChange={(e) => setValue('phone', formatPhoneNumber(e.target.value))}
                    validation={{
                      required: 'Phone number is required',
                      pattern: {
                        value: PHONE_NUMBER_CANADA,
                        message: 'Phone Number format is not valid',
                      },
                    }}
                    error={errors['phone']}
                  />
                </div>
              </div>
              <div className={cx('crFormInput')}>
                <label htmlFor='input' className={cx('crTitleInput')}>
                  Role
                </label>
                <Dropdown
                  register={register}
                  name={'role'}
                  selected={roleUser.values}
                  options={role}
                  setOptions={setRole}
                  valueUpdate={edit?.role}
                  show={showDropdown[1]}
                  index={1}
                  setshow={handleDropdown}
                  up={up}
                />
              </div>
              <div className={cx('crFormInput')}>
                <label htmlFor='input' className={cx('crTitleInput')}>
                  Status
                </label>
                <Dropdown
                  register={register}
                  name={'status'}
                  selected={statusUser.values}
                  options={status}
                  setOptions={setStatus}
                  valueUpdate={edit?.status ? 'Active' : 'Inactive'}
                  show={showDropdown[2]}
                  index={2}
                  up={up}
                  setshow={handleDropdown}
                />
              </div>
              {edit === undefined ? (
                <div className={cx('crCreateWrap')}>
                  <button type='submit' disabled={loading || isShowMessage} className={cx('crBtnCreate')}>
                    Create
                  </button>
                </div>
              ) : (
                <div className={cx('crCreateWrap')}>
                  <button type='submit' disabled={loading || isShowMessage} className={cx('crBtnCreate')}>
                    Save
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      {show && <div className={cx('crBackground')} onClick={handleClose}></div>}
    </div>
  );
};

export default CreateUser;
