import { call, put, takeLatest } from 'redux-saga/effects';
import authApi from 'src/apis/auth';
import { CheckRoleUser, StorageConstants } from 'src/utils/enum';
import { history } from 'src/utils/history';
import { exactRouter } from 'src/utils/routers';
import { authActions } from './authSlice';

function* handleLogin(action) {
  try {
    const response = yield call(authApi.login, action.payload);
    console.log('chekc1234 ', response);
    const data = response.data.data;
    const currentRole = data.user.role;
    const userProfile = data.user;

    localStorage.setItem(StorageConstants.ACCESS_TOKEN, data.token);
    localStorage.setItem(StorageConstants.CURRENT_USER, currentRole);
    localStorage.setItem(StorageConstants.USER_PROFILE, JSON.stringify(userProfile));

    if (data.user.role === CheckRoleUser.SUPER_ADMIN) {
      yield put(authActions.loginSuccess(data));
      history.push(exactRouter.superAdmin.listService);
    } else if (data.user.role === CheckRoleUser.ADMIN) {
      yield put(authActions.loginSuccess(data));
      history.push(exactRouter.admin.listHouseholds);
    }
  } catch (error) {
    yield put(authActions.loginFailed(error.response.data.message));
  }
}

function* handleForgotPassword(action) {
  try {
    const response = yield call(authApi.forgotPassword, action.payload);

    yield put(authActions.forgotPasswordSuccess(response));
  } catch (error) {
    yield put(authActions.forgotPasswordFailed(error.response.data.message));
  }
}

function* handleLogout() {
  yield call([localStorage, localStorage.clear]);
}

function* handleCreateNewPassword(action) {
  try {
    const response = yield call(authApi.createNewPassword, action.payload.body);
    const data = response.data.data;
    const currentRole = data.user.role;
    const userProfile = data.user;

    yield put(authActions.createNewPasswordSuccess(data));

    // After create new password success, then handle login and redirect to dashboard
    localStorage.setItem(StorageConstants.ACCESS_TOKEN, data.token);
    localStorage.setItem(StorageConstants.CURRENT_USER, currentRole);
    localStorage.setItem(StorageConstants.USER_PROFILE, JSON.stringify(userProfile));

    if (data.user.role === CheckRoleUser.SUPER_ADMIN) {
      yield put(authActions.loginSuccess(data));
      yield put(action.payload.navigate(exactRouter.superAdmin.listService));
    } else if (data.user.role === CheckRoleUser.ADMIN) {
      yield put(authActions.loginSuccess(data));
      yield put(action.payload.navigate(exactRouter.admin.listHouseholds));
    }
  } catch (error) {
    yield put(authActions.createNewPasswordFailed(error.response.data.message));
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.login.type, handleLogin);
  yield takeLatest(authActions.logout.type, handleLogout);
  yield takeLatest(authActions.forgotPassword.type, handleForgotPassword);
  yield takeLatest(authActions.createNewPassword.type, handleCreateNewPassword);
}
