import classNames from 'classnames/bind';
import React from 'react';

//scss
import styles from '../styles.module.scss';

const cx = classNames.bind(styles);
const TableHeader = (props) => {
  const { lOther } = props;

  return (
    <div id='hdHederService' className={lOther ? cx('hdTableHeaderlOther') : cx('hdTableHeader')}>
      <div className={cx('hdHeaderItem')}>Service Name</div>
      {/* <div className={cx('hdHeaderItem')}>Price($)</div>
        <div className={cx('hdHeaderItem')}>Inside($)</div>
        <div className={cx('hdHeaderItem')}>Outside($)</div>
        {lOther ? null : <div className={cx('hdHeaderItem')}>Both($)</div>}
        {lOther ? null : <div className={cx('hdHeaderItem')}>Status</div>} */}

      <div className={cx('hdHeaderItem')}>Actions</div>
    </div>
  );
};

export default TableHeader;
