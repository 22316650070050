//Library
import React from 'react';
import classNames from 'classnames/bind';
//Components
//Feature
//Utils
//Icon
//scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const TableHeaderUser = () => {
  return (
    <div id='umHeaderUser' className={cx('umTableHeader')}>
      {/* <div className={cx('umUserField')}>User Name</div> */}
      <div className={cx('umEmailField')}>Email</div>
      <div>Gender</div>
      <div className={cx('umPhoneNumber')}>Phone Number</div>
      <div>Role</div>
      <div>Status</div>
      <div className={cx('umActions')}>Actions</div>
    </div>
  );
};

export default TableHeaderUser;
