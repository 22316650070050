import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  show: null,
  title: null,
  buttonTitle: null,
  showComFig: null,
  function: null,
  functionCancel: null,
};

const digLogSlice = createSlice({
  name: 'diglog',
  initialState,
  reducers: {
    showDigLog(state, action) {
      state.show = true;
    },
    getTitle(state, action) {
      state.show = true;
      state.title = action.payload.title;
      state.function = action.payload.function;
    },

    getComFig(state, action) {
      state.showComFig = true;
      state.title = action.payload.title;
      state.function = action.payload.function;
      state.functionCancel = action.payload.functionCancel;
      state.buttonTitle = action.payload.buttonTitle;
    },
    resetState: () => initialState,
  },
});

export const digLogAction = digLogSlice.actions;

export const selectDigLogShow = (state) => state.digLog.show;
export const selectDigLogTitle = (state) => state.digLog.title;
export const selectDigLogComFig = (state) => state.digLog.showComFig;
export const selectDigLogFunction = (state) => state.digLog.function;
export const selectDialogFunctionCancel = (state) => state.digLog.functionCancel;
export const selectDialogButtonTitle = (state) => state.digLog.buttonTitle;

const digLogReducer = digLogSlice.reducer;
export default digLogReducer;
