import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  isLoading: null,
  isCombine: null,
  listSubService: [],
  listSubServiceNotCombine: [],
  total: null,
  paginationTotal: null,
  showAdd: false,
  typeAction: null,
  isShowMessage: null,
  isFetchDataAfterAction: false,
  filter: {
    page: 1,
    limit: 10,
    isActive: null,
    serviceGroupId: '',
    search: '',
  },
  message: '',
  isGetSubServiceSuccess: false,
};

const subServiceSlice = createSlice({
  name: 'subService',
  initialState,
  reducers: {
    // Get All SubService
    getAllSubService(state, actions) {
      state.loading = true;
      state.isGetSubServiceSuccess = false;
      state.isCombine = actions.payload.isCombine;
    },
    getAllSubServiceSuccess(state, actions) {
      state.loading = false;
      if (state.isCombine === false) {
        state.listSubServiceNotCombine = actions.payload.servicesNotCombine;
        state.total = actions.payload.servicesNotCombine.length;
        state.isGetSubServiceSuccess = true;
      } else {
        state.listSubService = actions.payload.services;
        state.total = actions.payload.total;
        state.paginationTotal = actions.payload.pagination.total;
      }
    },
    getAllSubServiceFail(state, action) {
      state.loading = false;
      state.isGetSubServiceSuccess = false;
    },

    // Create SubService
    createSubService(state, action) {
      state.loading = true;
      state.isFetchDataAfterAction = false;
    },
    createSubServiceSuccess(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = true;
      state.showAdd = true;
      state.isShowMessage = true;
      state.typeAction = 'CREATE';
    },
    createSubServiceFail(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },

    // Delete SubService
    deleteSubService(state, action) {
      state.loading = true;
      state.isFetchDataAfterAction = false;
    },
    deleteSubServiceSuccess(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = true;
      state.isShowMessage = true;
      state.typeAction = 'DELETE';
    },
    deleteSubServiceFailed(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },

    // Update SubService
    updateSubService(state, action) {
      state.loading = true;
      state.isFetchDataAfterAction = false;
    },
    updateSubServiceSuccess(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = true;
      state.showAdd = true;
      state.typeAction = 'UPDATE';
      state.isShowMessage = true;
    },
    updateSubServiceFail(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },

    // Filter
    filter(state, action) {
      state.filter = action.payload;
    },
    showAdd(state, action) {
      state.showAdd = true;
    },
    cloneAdd(state, action) {
      state.showAdd = false;
    },

    // Reset data in store
    resetListSubServiceNotCombine(state) {
      state.listSubServiceNotCombine = initialState.listSubServiceNotCombine;
    },
    resetState: () => initialState,
    resetStatus(state) {
      state.message = '';
      state.isShowMessage = null;
      state.typeAction = '';
    },
  },
});

export const subServiceActions = subServiceSlice.actions;

export const selectSubServiceList = (state) => state.subService.listSubService;
export const selectListSubServiceNotCombine = (state) => state.subService.listSubServiceNotCombine;
export const selectSubServiceTotal = (state) => state.subService.total;
export const selectSubServiceFilter = (state) => state.subService.filter;
export const selectSubServiceLoading = (state) => state.subService.loading;
export const selectSubServiceShowAdd = (state) => state.subService.showAdd;
export const selectSubServicePaginationTotal = (state) => state.subService.paginationTotal;
export const selectSubServiceTypeAction = (state) => state.subService.typeAction;
export const selectSubServiceMessage = (state) => state.subService.message;
export const selectSubServiceShowMessage = (state) => state.subService.isShowMessage;
export const selectSubServiceFetchDataAfterAction = (state) => state.subService.isFetchDataAfterAction;
export const selectIsGetSubServiceSuccess = (state) => state.subService.isGetSubServiceSuccess;
const subServiceReducer = subServiceSlice.reducer;
export default subServiceReducer;
