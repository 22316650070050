import classNames from 'classnames/bind';
import React from 'react';

// scss
import styles from './styles.module.scss';

const InputField = (props) => {
  const {
    register,
    name,
    placeholder,
    type,
    classNameWrap,
    min,
    max,
    setIsTypeService,
    classNameInput,
    label,
    classNameLabel,
    valueAsNumber,
    onChange,
    value,
    readonly,
    check,
    validation,
    error,
  } = props;
  const cx = classNames.bind(styles);

  return (
    <div className={cx(`${classNameWrap}`)}>
      {label && <label className={cx(`${classNameLabel}`)}>{label}</label>}
      {register ? (
        <>
          {type !== 'number' ? (
            <input
              spellCheck='false'
              type={type}
              {...register(`${name}`, validation)}
              minLength={min}
              maxLength={max}
              className={cx(`${classNameInput}`)}
              placeholder={placeholder}
              readOnly={readonly}
              onChange={(e) => onChange && onChange(e)}
              value={value && value}
            />
          ) : (
            <input
              type={type}
              min={min}
              max={max}
              {...register(`${name}`, validation)}
              // valueAsNumber={valueAsNumber}
              className={cx(`${classNameInput}`)}
              placeholder={placeholder}
              onChange={(e) => onChange && onChange(e)}
              value={value && value}
              readOnly={readonly}
              step='0.01'
            />
          )}
        </>
      ) : (
        <input
          type={type}
          onChange={(e) => {
            setIsTypeService(e.target.checked);
          }}
          checked={check}
          className={cx(`${classNameInput}`)}
          placeholder={placeholder}
        />
      )}
      {error && <p className={cx('ifValidateText')}>{error.message}</p>}
    </div>
  );
};

export default InputField;
