export const contentDialog = {
  FORGOT_PASSWORD: {
    SUCCESS: 'We have sent a link to reset your password. Please check your inbox',
    FAILED: 'Email not found',
    NONE: 'Please fill in a valid email address',
  },
  CREATE_NEW_PASSWORD: {
    FAILED: 'Password not match',
    NONE: 'Please fill in password and confirm field fields',
  },
  // Service
  CREATE_NEW_SERVICE: {
    SUCCESS: 'New service has been added successfully',
    FAILED: 'Name service was used',
  },
  DELETE_SERVICE: {
    SUCCESS: 'The service has been deleted successfully',
    FAILED: 'Delete Failed',
  },
  UPDATE_SERVICE: {
    SUCCESS: 'The service has been updated successfully.',
    FAILED: 'Update Failed',
  },
  //Subservice
  CREATE_NEW_SUBSERVICE: {
    SUCCESS: 'New sub-service has been added successfully',
    FAILED: 'Name sub-service was used',
    NONE: 'Please fill in password and confirm field fields',
  },
  DELETE_SUBSERVICE: {
    SUCCESS: 'The sub-service has been deleted successfully',
    FAILED: 'Delete Failed',
  },
  UPDATE_SUBSERVICE: {
    SUCCESS: 'The sub-service has been updated successfully.',
    FAILED: 'Update Failed',
  },
  // Orders
  DELETE_ORDER: {
    SUCCESS: 'The order has been deleted successfully',
    FAILED: 'Delete Failed',
  },
  UPDATE_ORDER: {
    SUCCESS: 'The order has been updated successfully',
    FAILED: 'Update Failed',
  },
  // Combine Service
  CREATE_COMBINE_SERVICE: {
    SUCCESS: 'New combine service has been added successfully',
    FAILED: 'Create Failed',
  },
  UPDATE_COMBINE_SERVICE: {
    SUCCESS: 'The combine service has been updated successfully.',
    FAILED: 'Update Failed',
  },
  DELETE_COMBINE_SERVICE: {
    SUCCESS: 'The combine service has been deleted successfully',
    FAILED: 'Delete Failed',
  },
  // Household
  CREATE_HOUSEHOLD: {
    SUCCESS: 'New household has been added successfully',
    FAILED: 'Create Failed',
  },
  UPDATE_HOUSEHOLD: {
    SUCCESS: 'The household updated successfully!',
    FAILED: 'Update Failed',
  },
  DELETE_HOUSEHOLD: {
    SUCCESS: 'Household has been deleted successfully',
    FAILED: 'Delete Failed',
  },
  // User Management
  CREATE_USER_MANAGEMENT: {
    SUCCESS: 'New user has been added successfully',
    FAILED: 'Create Failed',
  },
  UPDATE_USER_MANAGEMENT: {
    SUCCESS: 'The user has been updated successfully.',
    FAILED: 'Update Failed',
  },
  DELETE_USER_MANAGEMENT: {
    SUCCESS: 'The user has been deleted successfully',
    FAILED: 'Delete Failed',
  },

  DELETE: {
    CLICK: 'Are you sure you want to delete this record?',
    SUCCESS: 'Delete Success !',
    FAILED: 'Delete Failed',
  },

  DELETE_CONFIRM: {
    CONFIRM: (key) => `Are you sure you want to delete ${key}?`,
  },

  UPDATE: {
    SUCCESS: 'Update Success !',
    FAILED: 'Update Failed',
  },
  CREATE: {
    SUCCESS: 'Create Success !',
    FAILED: 'Create Failed !',
  },
  LOGIN_FAILED: {
    USER_NOT_FOUND: 'Email not found',
    PASSWORD_INCORRECT: 'Incorrect password',
  },
  SEND_LINK_FORGOT: {
    EMAIL_NOT_FOUND: 'Email not found',
  },
  HOUSER_HOLD: {
    HOUSEHOLD_NAME_NOT_FOUND: '',
    HOUSEHOLD_PHONE_NOT_FOUND: 'Phone already exists',
    HOUSEHOLD_MAIL_NOT_FOUND: 'Email already exists',
  },
  SERVICES: {
    EMAIL_ALREADY_EXIST: 'Email already exists',
    SERVICES_ERROR: 'Name service was used',
  },
  IMAGE: {
    NOT_FILE_IMAGE: 'Incorrect Image Format',
    MAX_IMAGE: 'Image size is too big',
    NOT_IMAGE: 'Can not leave the picture blank',
  },
  FORGOT_PASSWORDD: {
    FAILED: 'Sorry, The link has been expired.',
  },
};

/**
 * error message from backend
 */
export const errorsResponse = {
  AUTH_ERROR: {
    USER_NOT_FOUND: 'User not found',
    PASSWORD_INCORRECT: 'Password incorrect',
    EMAIL_NOT_FOUND: 'Email not found',
    EMAIL_IS_EXISTED: '',
  },
  SERVICES: {
    SERVICES_ALREADY_EXIST: 'Email already exists',
    SERVICES_ERROR: 'Name service was used',
  },
  HOUSEHOLD: {
    HOUSEHOLD_NAME_NOT_FOUND: '',
    HOUSEHOLD_PHONE_NOT_FOUND: 'Phone already exists',
    HOUSEHOLD_MAIL_NOT_FOUND: 'Email already exists',
  },
  USE_MANAGEMENT: {
    USE_MANAGEMENT_ALREADY_EXIST: '',
  },
  FORGOT_PASSWORD: {
    FAILED: 'jwt expired',
  },
  PRICE: {
    POSITIVE: 'must be positive',
  },
};
