import classNames from 'classnames/bind';
import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//import slice
import { digLogAction } from 'src/features/digLog/slice';
import { selectSubServiceShowAdd, subServiceActions } from 'src/features/superAdmin/subService/slice';
//import component
import CreateSubService from '../../../create';
//import icon
import iconEdit from 'src/assets/icons/ic_edit.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';
import iconDeleteBox from 'src/assets/icons/ic_delete_box.svg';
import iconCheckBox from 'src/assets/icons/ic_check_box.svg';
//import image
import User from 'src/assets/images/img_logo_clean_window.png';
//import scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const TableHeaderMob = (props) => {
  const dispatch = useDispatch();

  const addShow = useSelector(selectSubServiceShowAdd);

  const [showSubService, setShowSubService] = useState(false);
  const [listSubService, setListSubService] = useState();

  const {
    _id,
    serviceGroup,
    name,
    typeServices,
    isActive,
    isOther,
    active,
    logo,
    lOther,
    note,
    emptySlot1,
    emptySlot2,
    emptySlot3,
    emptySlot4,
    emptySlot5,
  } = props;

  const editSubService = () => {
    setListSubService({
      name: name,
      price: typeServices[0]?.price,
      id_price: typeServices[0]?._id,
      inside: typeServices[0]?.price,
      id_inside: typeServices[0]?._id,
      outside: typeServices[1]?.price,
      id_outside: typeServices[1]?._id,
      both: typeServices[2]?.price,
      id_both: typeServices[2]?._id,
      id: _id,
      isActive: isActive,
      isOther: isOther,
      logo: logo,
      serviceGroup: serviceGroup,
      note,
      emptySlot1,
      emptySlot2,
      emptySlot3,
      emptySlot4,
      emptySlot5,
    });
    setShowSubService(true);
  };

  const deleteSubService = () => {
    dispatch(
      digLogAction.getComFig({
        title: `Delete ${name}`,
        function: () => {
          dispatch(subServiceActions.deleteSubService(_id));
        },
      })
    );
  };

  useEffect(() => {
    if (addShow === true) {
      setShowSubService(false);
    }
  }, [addShow]);

  return (
    <div id='itHdHeaderItemMob' className={cx('itHdTableBlock')}>
      <div className={cx('itHdItemActions')}>
        <div className={cx('itHdBtnGroupDelete')}>
          <img src={iconDelete} alt='trashIcon' onClick={deleteSubService} className={cx('itHdButtonDelete')} />
        </div>
        <div className={cx('itHdBtnGroupEdit')}>
          <img src={iconEdit} alt='editIcon' onClick={editSubService} className={cx('itHdButtonEdit')} />
        </div>
      </div>
      <div className={cx('itHdServiceImg')}>
        <img
          src={logo || User}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = User;
          }}
          alt=''
          className={cx('itHdImg')}
        />
      </div>
      <div className={cx('itHdTableItem')}>
        <div className={cx('itName')}>Sub Services</div>
        <div className={cx('itTableItem')}>{name || 'N/A'}</div>
      </div>
      <div className={cx('itHdTableItem')}>
        <div className={cx('itName')}>Price($)</div>
        <div className={cx('itTableItem')}>
          {typeServices[0]?.price == null || typeServices.length > 1 ? '-' : typeServices[0]?.price}
        </div>
      </div>
      <div className={cx('itHdTableItem')}>
        <div className={cx('itName')}>InSide($)</div>
        <div className={cx('itTableItem')}>
          {typeServices[0]?.price == null || typeServices.length < 3 ? '-' : typeServices[0]?.price}
        </div>
      </div>
      <div className={cx('itHdTableItem')}>
        <div className={cx('itName')}>Outside($)</div>
        <div className={cx('itTableItem')}>
          {typeServices[1]?.price == null || typeServices.length < 3 ? '-' : typeServices[1]?.price}
        </div>
      </div>

      <div className={cx('itHdTableItem')}>
        <div className={cx('itName')}>Both($)</div>
        <div className={cx('itTableItem')}>
          {typeServices[2]?.price == null || typeServices.length < 3 ? '-' : typeServices[2]?.price}
        </div>
      </div>

      {lOther ? null : (
        <div className={cx('itHdTableItem')}>
          <div className={cx('itName')}>Status</div>
          <div className={cx('itTableItem')}>
            {isActive ? (
              <div className={cx('itBorderIconCheck')}>
                <img src={iconCheckBox} className={cx('itTbCheckboxCheck')} alt='icon' />
              </div>
            ) : (
              <div className={cx('itBorderIconDelete')}>
                <img src={iconDeleteBox} className={cx('itTbCheckbox')} alt='icon' />
              </div>
            )}
          </div>
        </div>
      )}

      <div>
        <CreateSubService
          showSubService={showSubService}
          setShowSidebar={setShowSubService}
          title={true}
          listEdit={listSubService}
        />
      </div>
    </div>
  );
};

export default TableHeaderMob;
