import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
//filter
import { digLogAction, selectDigLogTitle, selectDigLogShow, selectDigLogFunction } from 'src/features/digLog/slice';

//scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const DigLog = () => {
  const dispatch = useDispatch();
  /**
   * select
   */
  const title = useSelector(selectDigLogTitle);
  const show = useSelector(selectDigLogShow);
  const functionHandel = useSelector(selectDigLogFunction);

  const resetDigLog = () => {
    functionHandel && functionHandel();
    dispatch(digLogAction.resetState());
  };

  return (
    <div>
      {show ? (
        <>
          <div className={cx('dlWrapper')}></div>
          <div className={cx('dbDigLog')}>
            <div className={cx('dbBlock')}>
              <div>
                <p className={cx('dlTitle')}>{title}</p>
              </div>
              <div>
                <button className={cx('dlBnt')} onClick={resetDigLog}>
                  OK
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DigLog;
