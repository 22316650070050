import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Button } from 'devextreme-react';

// component
import TableHeaderRequest from './table/tableHeader/TableHeader';
import TableItemRequest from './table/tableItem/TableItem';
import TableCardMob from './table/tablecardmob/TableCatdMob';
import PagiNation from 'src/components/common/pagination/Pagination';
import NoDataAvailable from 'src/components/common/noDataAvailable/NoDataAvaliable';
import Filter from 'src/components/common/filter/Filter';
import { CheckRoleUser, FilterTypeRequest } from 'src/utils/enum';
import { FilterStatusRequest } from 'src/utils/enum';
import {
  requestActions,
  selectAllRequest,
  selectFilterRequest,
  selectTotalRequest,
  selectIsSuccessRequest,
  selectPaginationTotal,
  selectIsShowMessage,
  selectTypeAction,
} from 'src/features/superAdmin/requests/slice';
import { selectAuthRole } from 'src/features/auth/authSlice';
import EditOrders from 'src/pages/superAdmin/orders/edit';

//icon
import iconFilter from 'src/assets/icons/ic_filter.svg';

//utils
import helpFunction from 'src/utils/help';
import { getCurrentTimezone } from 'src/utils/help';
import { exactRouter } from 'src/utils/routers';

//scss
import styles from './styles.module.scss';
import { digLogAction } from 'src/features/digLog/slice';
import { contentDialog } from 'src/utils/contentDialog';

const ListRequests = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const requests = useSelector(selectAllRequest);
  const total = useSelector(selectTotalRequest);
  const paginationTotalll = useSelector(selectPaginationTotal);
  const filterEntry = useSelector(selectFilterRequest);
  const isSuccess = useSelector(selectIsSuccessRequest);
  const role = useSelector(selectAuthRole);
  const isShowMessage = useSelector(selectIsShowMessage);
  const typeAction = useSelector(selectTypeAction);

  const [isOpen, setIsOpen] = useState([]);
  const [showType, setShowType] = useState();
  const [showStatus, setShowStatus] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [dateSelect, setDateSelect] = useState('');
  const [calender, setCalender] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [listEdit, setListEdit] = useState(undefined);

  const btnFilter = useRef();
  console.log(`[ListRequests] filterEntry -> ${JSON.stringify(filterEntry)}`);

  const handleOpen = (index) => {
    const arr = requests.map((s) => false);
    arr[index] = true;
    setIsOpen(arr);
  };

  const handlePageChange = (value) => {
    dispatch(requestActions.filterRequest(value));
    const arr = [...Array(total)].map((s) => false);
    setIsOpen(arr);
  };

  // TODO: Remove this useEffect
  useEffect(() => {
    dispatch(requestActions.getAllRequest(filterEntry));
  }, [dispatch, isSuccess, filterEntry]);

  useEffect(() => {
    if (isShowMessage === true && typeAction === 'DELETE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.DELETE_ORDER.SUCCESS,
          function: () => {
            dispatch(requestActions.resetStatus());
          },
        })
      );
    }
    if (isShowMessage === true && typeAction === 'UPDATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.UPDATE_ORDER.SUCCESS,
          function: () => {
            dispatch(requestActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, isShowMessage, typeAction]);

  /**
   * this is using loop array -> false
   * when element is true -> close element false
   */

  useEffect(() => {
    dispatch(requestActions.resetState());
  }, [dispatch]);

  useEffect(() => {
    const arr = [...Array(total)].map((s) => false);
    setIsOpen(arr);

    const timezone = getCurrentTimezone();
    console.log(`[ListRequests] timezone -> ${timezone}`);

    dispatch(requestActions.filterRequest({ ...filterEntry, timezone }));
  }, []);

  useEffect(() => {
    // GET Current Date
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    setDateSelect(defaultValue);
  }, [dateSelect]);

  // Function handle
  const handleChangeFilterTypeRequest = (index) => {
    dispatch(requestActions.filterRequest({ ...filterEntry, nameTypeService: FilterTypeRequest.keys[index], page: 1 }));
    handleOpen(total + 1);
  };

  const handleChangeFilterStatusRequest = (index) => {
    dispatch(requestActions.filterRequest({ ...filterEntry, status: FilterStatusRequest.keys[index], page: 1 }));
    handleOpen(total + 1);
  };

  // const handleFilterDate = (event) => {
  //   dispatch(requestActions.filterRequest({ ...filterEntry, date: event.target.value, page: 1 }));
  // };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleFilterDate = (e) => {
    const date = e.target.value;
    setCalender(date);
    setDateSelect(date);
    dispatch(requestActions.filterRequest({ ...filterEntry, date: date, page: 1 }));
    handleOpen(total + 1);
  };

  helpFunction.useOutside(btnFilter, setShowFilter);

  return (
    <>
      <EditOrders showEdit={isEdit} setShowEdit={setIsEdit} listEdit={listEdit} setListEdit={setListEdit} />

      <div className={cx('lsTitle')}>
        <p className={cx('lsRequestsName')}>Orders: {total || '0'}</p>
      </div>
      {/* <div className={cx('lsTabs')}>
        <Button
          text='Households'
          activeStateEnabled={false}
          focusStateEnabled={false}
          className={cx('lBntTab')}
          onClick={() => {
            if (role === CheckRoleUser.SUPER_ADMIN) {
              navigate(`${exactRouter.superAdmin.listHouseholds}`);
            } else if (role === CheckRoleUser.ADMIN) {
              navigate(`${exactRouter.admin.listHouseholds}`);
            }
          }}
        />
        <Button
          text='Requests'
          activeStateEnabled={false}
          focusStateEnabled={false}
          className={cx('lBntTabRequests')}
          onClick={() => {
            if (role === CheckRoleUser.SUPER_ADMIN) {
              navigate(`${exactRouter.superAdmin.listRequests}`);
            } else if (role === CheckRoleUser.ADMIN) {
              navigate(`${exactRouter.admin.listRequests}`);
            }
          }}
        />
      </div> */}

      <div className={cx('lWrapper')}>
        <div className={cx('lFilterWrap')} ref={btnFilter}>
          <div>
            <button className={cx('lBtnFilter')} onClick={handleShowFilter}>
              <img src={iconFilter} className={cx('iconFilter')} alt={iconFilter} />
              <span className={cx('iconNameFilter')}>Filter</span>
            </button>
          </div>
          {showFilter ? (
            <div className={cx('lqFilterBlock')}>
              <Filter
                filter={FilterTypeRequest.values}
                selected={showType}
                setSelected={setShowType}
                className={true}
                handleChangeFilter={handleChangeFilterTypeRequest}
              />
              <Filter
                filter={FilterStatusRequest.values}
                selected={showStatus}
                setSelected={setShowStatus}
                className={true}
                handleChangeFilter={handleChangeFilterStatusRequest}
              />
              <div className={cx('lqDateBlockTime')}>
                <input
                  type='date'
                  value={calender}
                  defaultValue={dateSelect}
                  className={cx('lqDateInput')}
                  onChange={handleFilterDate}
                />
              </div>
            </div>
          ) : null}
        </div>

        <div className={cx('lItemDesktop')}>
          <TableHeaderRequest />
          <div className={`${cx('lItem')} ${showFilter ? cx('lItemFilter') : cx('')}`}>
            {paginationTotalll > 0 ? (
              requests.map((item, idx) => (
                <TableItemRequest
                  props={item}
                  key={idx}
                  isOpen={isOpen[idx]}
                  // setIsOpen={setIsOpen}
                  handleOpen={handleOpen}
                  index={idx}
                  // isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  // listEdit={listEdit}
                  setListEdit={setListEdit}
                />
              ))
            ) : (
              <NoDataAvailable />
            )}
          </div>
        </div>
        <div>
          {paginationTotalll > 0 ? (
            <div className={cx('lItemMob')}>
              {requests.map((item, id) => (
                <TableCardMob
                  props={item}
                  key={Math.random()}
                  // setIsOpen={setIsOpen}
                  handleOpen={handleOpen}
                  setIsEdit={setIsEdit}
                  setListEdit={setListEdit}
                  index={id}
                />
              ))}
            </div>
          ) : (
            <NoDataAvailable />
          )}
        </div>
        {paginationTotalll > 0 ? (
          <div className={cx('lPagination')}>
            <div className={cx('lLegend')}>
              <div className={cx('lLegendItem')}>
                <div className={cx('lConnectedStatus')}></div>
                <span className={cx('Inside')}>Inside</span>
              </div>

              <div className={cx('lLegendItem')}>
                <div className={cx('lDisconnectedStatus')}></div>
                <span className={cx('Outside')}>Outside</span>
              </div>

              <div className={cx('lLegendItem')}>
                <div className={cx('lNotConnectStatus')}></div>
                <span className={cx('Both')}>Both</span>
              </div>
              <div className={cx('lLegendItem')}>
                <div className={cx('OtherConnectStatus')}></div>
                <span className={cx('Other')}>N/A</span>
              </div>
            </div>
            <PagiNation filter={filterEntry} total={paginationTotalll} handlePageChange={handlePageChange} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ListRequests;
