import classNames from 'classnames/bind';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

// Utils
import { listHouseSize } from 'src/utils/enum';
import { capitalizeFirstLetter } from 'src/utils/help';

//import components
import { digLogAction } from 'src/features/digLog/slice';
import { houseActions } from 'src/features/superAdmin/household/slice';

//import icon
import iconEdit from 'src/assets/icons/ic_edit.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';

//scss
import styles from './styles.module.scss';
import { contentDialog } from 'src/utils/contentDialog';

const TableItemHouseHold = ({ household, setShowHouse, showHouse, setListEdit }) => {
  const cx = classNames.bind(styles);

  // const [listHouse, setListHouse] = useState();

  const dispatch = useDispatch();
  const { _id, name, houseSize, email, householdId, phoneNumber, status } = household;

  const householdStatus = {
    CONNECTED: 'connectedStatus',
    NOT_CONNECT: 'notConnectStatus',
    DISCONNECT: 'disconnectStatus',
  };

  const editHouser = () => {
    setListEdit(household);
    setShowHouse(true);
  };

  const deleteHousehold = () => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE.CLICK,
        function: () => {
          dispatch(houseActions.deleteHousehold({ _id: _id, isActive: false }));
        },
      })
    );
  };

  return (
    <div className={cx('itHhTableItem')}>
      <p className={cx('itItem')} title={householdId || 'N/A'}>
        {householdId || 'N/A'}
      </p>
      <p className={cx('itItem')} title={name.firstName + ' ' + name.lastName || 'N/A'}>
        {name.firstName + ' ' + name.lastName || 'N/A'}
      </p>
      <p className={`${cx('itItem')} ${cx('itItemHouseSize')}`}>{capitalizeFirstLetter(houseSize)}</p>
      <p className={cx('itItem')}>{phoneNumber.phone || 'N/A'}</p>
      <div className={cx('statusBlock')}>
        <div className={cx(`${householdStatus[status]}`)} />
      </div>
      <div className={cx('itHhItemHouseHold')}>
        <div className={cx('itHhBtnGroupEdit')} onClick={editHouser}>
          <img src={iconEdit} alt='editIcon' className={cx('itHhButtonEdit')} />
        </div>
        <div className={cx('itHhBtnGroupDelete')}>
          <img src={iconDelete} alt='iconDelete' className={cx('itHhButtonDelete')} onClick={deleteHousehold} />
        </div>
      </div>
      {/* <PagiNation /> */}
    </div>
  );
};

export default TableItemHouseHold;
