import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Component
import Dropdown from 'src/components/dropdown';
import { serviceDropdown } from 'src/utils/enum';
import InputField from 'src/components/inputField';

//features
import {
  serviceActions,
  selectServiceShowAdd,
  selectServiceShowMessage,
  selectServiceLoading,
} from 'src/features/superAdmin/service/slice';
import { digLogAction } from 'src/features/digLog/slice';
//

// scss
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

//
import { contentDialog } from 'src/utils/contentDialog';

/** image & icon */
import imgAvatar from 'src/assets/images/img_logo_clean_window.png';
import iconUpdate from 'src/assets/icons/ic_edit_logo.svg';
import iconClose from 'src/assets/icons/ic_close.svg';
import TextareaField from 'src/components/textareaField';

const CreateService = (props) => {
  const dispatch = useDispatch();
  const cx = classNames.bind(styles);
  const { showService, showSidebar, setShowSidebar, listEdit, title } = props;
  console.log(`[CreateService] props -> ${JSON.stringify(props, null, 2)}`);

  const addShow = useSelector(selectServiceShowAdd);
  const isShowMessage = useSelector(selectServiceShowMessage);
  const loading = useSelector(selectServiceLoading);

  const [options, setOptions] = useState(listEdit?.isActive === undefined ? ' ACTIVE' : null);
  const [dataForm, setDataForm] = useState();
  const [fileUrl, setFileUrl] = useState(null);
  const [imgFile, setImgFile] = useState('');
  const [show, setShow] = useState();
  const [up, setUp] = useState(false);

  const serviceForm = useRef(null);

  const [isTypeService, setIsTypeService] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  let formData = new FormData();

  const processImage = (e) => {
    const file = e.target.files[0];
    console.log('file', file);

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      if (!listEdit?.logo) {
        dispatch(digLogAction.getTitle({ title: contentDialog.IMAGE.NOT_FILE_IMAGE }));
      }
      console.log('file.name', file.name);
      return false;
    }

    if (file.size > 2000000) {
      dispatch(digLogAction.getTitle({ title: contentDialog.IMAGE.MAX_IMAGE }));
      console.log('file.size', file.size);
      return false;
    }

    const imageUrl = URL.createObjectURL(file);
    console.log('imageUrl', imageUrl);
    setFileUrl(imageUrl);
    let fromData = new FormData();
    fromData.append('file', file);
    setImgFile(fromData);
  };

  const handleCloseSidebar = () => {
    setShowSidebar(false);
    setShow(false);
    reset();
    setFileUrl(null);
    setOptions(null);
    setIsTypeService(false);
    setUp(!up);
    if (serviceForm.current) {
      serviceForm.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    listEdit ? setIsTypeService(!listEdit?.isOther) : setIsTypeService(false);

    if (listEdit?.isOther) {
      setIsTypeService(false);
    } else {
      setIsTypeService(!listEdit?.isOther);
    }
  }, [listEdit]);

  const createSubmit = (data) => {
    if (data.name) {
      if (data.name.trim() === '') {
        dispatch(digLogAction.getTitle({ title: 'Name is required' }));
        return false;
      }
    }
    if (!fileUrl) {
      if (!listEdit?.img) {
        dispatch(digLogAction.getTitle({ title: contentDialog.IMAGE.NOT_IMAGE }));
        return false;
      }
    }
    const serviceInput = {
      name: data.name,
      note: data.note?.trim(),
      emptySlot1: data.emptySlot1?.trim(),
      emptySlot2: data.emptySlot2?.trim(),
      emptySlot3: data.emptySlot3?.trim(),
      emptySlot4: data.emptySlot4?.trim(),
      emptySlot5: data.emptySlot5?.trim(),
    };

    if (!listEdit) {
      dispatch(serviceActions.createService({ serviceInput: serviceInput, fileUrl: imgFile }));
    } else {
      dispatch(
        serviceActions.updateService({
          id: listEdit?.id,
          data: { ...serviceInput },
          fileUrl: imgFile || 1,
          imgUpdate: listEdit?.logo,
        })
      );
    }

    if (serviceForm.current) {
      serviceForm.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (showService === true) {
      setShow(true);
    }
  }, [showService]);

  useEffect(() => {
    if (addShow === true) {
      // reset();
      setShow(false);
    }
  }, [addShow]);

  useEffect(() => {
    reset(listEdit);
  }, [listEdit]);

  useEffect(() => {
    if (isShowMessage === true) {
      reset();
      setFileUrl(null);
      setOptions(null);
      setIsTypeService(false);
      setUp(!up);
    }
  }, [isShowMessage]);

  return (
    <>
      <div className={`${cx('crServiceContainer')} ${show ? cx('crServiceContainerShow') : cx('')}`}>
        <div className={cx('crWrapperService')} id='createService'>
          <div className={cx('crServiceContainerShow')}>
            <div className={cx('crContent')}>
              <div className={cx('crBtnClose')} onClick={handleCloseSidebar}>
                <img src={iconClose} alt='close' />
              </div>
              <div className={cx('crFormWrap')}>
                <span className={cx('crTitle')}> {title ? `Edit Service ` : 'Create Service'} </span>
                <div className={cx('crWrapLogo')}>
                  <div className={cx('crWrapAvatar')}>
                    <div className={cx('crLogo1')}>
                      <img src={fileUrl || listEdit?.img || imgAvatar} alt='Avatar' className={cx('crLogo')} />
                    </div>
                    <div className={cx('crWrapIconEdit')}>
                      <input type='file' className={cx('crInputFile')} value='' onChange={processImage} />
                      <img src={iconUpdate} alt='' className={cx('crIconEdit')} />
                    </div>
                  </div>
                </div>

                <form className={cx('crForm')} onSubmit={handleSubmit(createSubmit)}>
                  <div className={cx('crFormScroll')} ref={serviceForm}>
                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Service Name*'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'name'}
                      type={'text'}
                      min={3}
                      max={50}
                      validation={{
                        required: 'Service name is required',
                      }}
                      onChange={(e) => setValue('name', e.target.value)}
                      classNameInput={`${cx('crValueInput')} ${cx('crValueServiceName')}`}
                      error={errors['name']}
                    />

                    <TextareaField
                      classNameWrap={cx('crFormTextarea')}
                      label={'Note'}
                      classNameLabel={cx('crTitleTextarea')}
                      register={register}
                      name={'note'}
                      onChange={(e) => setValue('note', e.target.value)}
                      classNameTextarea={`${cx('crValueTextarea')}`}
                    />

                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Empty Slot'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'emptySlot1'}
                      type={'text'}
                      onChange={(e) => setValue('emptySlot1', e.target.value)}
                      classNameInput={`${cx('crValueInput')}`}
                    />

                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Empty Slot'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'emptySlot2'}
                      type={'text'}
                      onChange={(e) => setValue('emptySlot2', e.target.value)}
                      classNameInput={`${cx('crValueInput')}`}
                    />

                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Empty Slot'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'emptySlot3'}
                      type={'text'}
                      onChange={(e) => setValue('emptySlot3', e.target.value)}
                      classNameInput={`${cx('crValueInput')}`}
                    />

                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Empty Slot'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'emptySlot4'}
                      type={'text'}
                      onChange={(e) => setValue('emptySlot4', e.target.value)}
                      classNameInput={`${cx('crValueInput')}`}
                    />

                    <InputField
                      classNameWrap={cx('crFormInput')}
                      label={'Empty Slot'}
                      classNameLabel={cx('crTitleInput')}
                      register={register}
                      name={'emptySlot5'}
                      type={'text'}
                      onChange={(e) => setValue('emptySlot5', e.target.value)}
                      classNameInput={`${cx('crValueInput')}`}
                    />
                  </div>

                  <div className={cx('crWrapBtn')}>
                    {listEdit === undefined ? (
                      <button type='submit' disabled={loading || isShowMessage} className={cx('btnCreate')}>
                        Create
                      </button>
                    ) : (
                      <button type='submit' disabled={loading || isShowMessage} className={cx('btnCreate')}>
                        Save
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSidebar || showService ? <div className={cx('Overlay')} onClick={handleCloseSidebar}></div> : null}
    </>
  );
};

export default CreateService;
