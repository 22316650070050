import React from 'react';
import classNames from 'classnames/bind';

// scss
import styles from './styles.module.scss';

const Overlay = (props) => {
  const cx = classNames.bind(styles);
  const { setShowSidebar, setShowHouse, handleCloseSidebar } = props;

  const handleOverlayClick = () => {
    setShowHouse && setShowHouse(false);
    setShowSidebar && setShowSidebar(false);
    handleCloseSidebar && handleCloseSidebar();
  };

  return <div className={cx('overlay')} onClick={handleOverlayClick}></div>;
};

export default Overlay;
