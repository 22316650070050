import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
//icon
import SearchIcon from 'src/assets/icons/ic_search.svg';
//slice
import { selectIsGetSubServiceSuccess } from 'src/features/superAdmin/subService/slice';
//scss
import styles from './styles.module.scss';
//utils
const cx = classNames.bind(styles);

const Search = (props) => {
  const { handleSearch, placeholder, onScrollTo } = props;
  const [searchKey, setSearchKey] = useState('');
  const typingTimeOutRef = useRef(null);
  const isGetSubServiceSuccess = useSelector(selectIsGetSubServiceSuccess);

  useEffect(() => {
    if (isGetSubServiceSuccess) {
      setSearchKey('');
    }
  }, [isGetSubServiceSuccess]);

  const onValueChange = (e) => {
    setSearchKey(e.target.value);
    if (typingTimeOutRef.current) {
      clearTimeout(typingTimeOutRef.current);
    }

    if (e.target.value === '') {
      typingTimeOutRef.current = setTimeout(() => {
        handleSearch('');
      }, 300);
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    if (typingTimeOutRef.current) {
      clearTimeout(typingTimeOutRef.current);
    }

    if (handleSearch) {
      typingTimeOutRef.current = setTimeout(() => {
        handleSearch(searchKey.trim());
      }, 300);
      if (onScrollTo) {
        onScrollTo();
      }
    }
  };

  return (
    <form id='s-search' className={cx('s-search-form')} onSubmit={onSearch}>
      <div className={cx('s-search-form-content')}>
        <div className={cx('s-search-form-btn')}>
          <img src={SearchIcon} alt='searchIcon' onClick={onSearch} />
        </div>
        <input
          spellCheck='false'
          type='text'
          name='search'
          className={cx('s-search-form-input-field')}
          placeholder={placeholder}
          value={searchKey}
          autoComplete='off'
          onChange={onValueChange}
        />
      </div>
    </form>
  );
};

export default Search;
