import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { SuperAdminLayout, AdminLayout, MainLayout, AuthLayout } from 'src/layouts';
import CustomRouter from './components/common/customRouter';
import Loading from './components/common/loading/Loading';
import ProtectedRoutes from './components/common/protectedRoutes/ProtectedRoutes';
import { superAdminRoutes, authRoutes, adminRoutes } from './routes/superAdmin';
import { history } from './utils/history';

const App = () => {
  return (
    <>
      <CustomRouter history={history}>
        <div className='App'>
          <Routes>
            <Route path='/' element={<MainLayout />}></Route>

            <Route path='auth' exact element={<AuthLayout />}>
              {authRoutes.map((route, index) => {
                const Page = route.component;
                return <Route key={index} index={route.index ?? false} path={route.path} element={<Page />} />;
              })}
            </Route>

            <Route path='super-admin' element={<ProtectedRoutes Component={SuperAdminLayout} />}>
              {superAdminRoutes.map((route, index) => {
                const Page = route.component || '';

                return (
                  <Route key={index} path={route.path} element={<Page />}>
                    {route.children &&
                      route.children.length > 0 &&
                      route.children.map((childRoute, index) => {
                        const ChildComponent = childRoute.component;
                        return (
                          <Route
                            key={index}
                            path={childRoute.path}
                            index={childRoute.index ?? false}
                            element={<ChildComponent />}
                          />
                        );
                      })}
                  </Route>
                );
              })}
            </Route>

            <Route path='admin' element={<ProtectedRoutes Component={AdminLayout} />}>
              {adminRoutes.map((route, index) => {
                const Page = route.component || '';

                return (
                  <Route key={index} path={route.path} element={<Page />}>
                    {route.children &&
                      route.children.length > 0 &&
                      route.children.map((childRoute, index) => {
                        const ChildComponent = childRoute.component;
                        return (
                          <Route
                            key={index}
                            path={childRoute.path}
                            index={childRoute.index ?? false}
                            element={<ChildComponent />}
                          />
                        );
                      })}
                  </Route>
                );
              })}
            </Route>
          </Routes>
        </div>
      </CustomRouter>
      <Loading />
    </>
  );
};

export default App;
