import classNames from 'classnames/bind';
import React from 'react';
import { Outlet } from 'react-router-dom';

//utils

//icon
//image
//scss
import styles from './styles.module.scss';
const cx = classNames.bind(styles);

const SubService = () => {
  return (
    <div className={cx('wrapper')}>
      <Outlet />
    </div>
  );
};

export default SubService;
