import { createSlice } from '@reduxjs/toolkit';
import { StorageConstants } from 'src/utils/enum';

const initialState = {
  loading: false,
  accessToken: localStorage.getItem(StorageConstants.ACCESS_TOKEN) || null,
  currentRole: localStorage.getItem(StorageConstants.CURRENT_USER) || null,
  userProfile: JSON.parse(localStorage.getItem(StorageConstants.USER_PROFILE)) || {},
  isStatus: null,
  message: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetStatus(state) {
      state.isStatus = null;
      state.message = null;
    },

    // Login
    login(state, action) {
      state.loading = true;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.accessToken = action.payload.token;
      state.currentRole = action.payload.user.role;
      state.userProfile = action.payload.user;
    },
    loginFailed(state, action) {
      state.loading = false;
      state.isStatus = false;
      state.message = action.payload;
    },

    // ForgotPassword
    forgotPassword(state, action) {
      state.loading = true;
    },
    forgotPasswordSuccess(state, action) {
      state.loading = false;
      state.isStatus = true;
    },
    forgotPasswordFailed(state, action) {
      state.loading = false;
      state.isStatus = false;
      state.message = action.payload;
    },

    // Create New Password && login
    createNewPassword(state, action) {
      state.loading = true;
    },
    createNewPasswordSuccess(state, action) {
      state.loading = false;
    },
    createNewPasswordFailed(state, action) {
      state.loading = false;
      state.isStatus = false;
      state.message = action.payload;
    },

    logout(state, action) {
      state.loading = false;
      state.accessToken = null;
      state.currentRole = null;
      state.userProfile = {};
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Selector
export const selectAuthToken = (state) => state.auth.accessToken;
export const selectAuthLoading = (state) => state.auth.loading;
export const selectAuthRole = (state) => state.auth.currentRole;
export const selectUserProfile = (state) => state.auth.userProfile;
export const selectAuthStatus = (state) => state.auth.isStatus;
export const selectAuthMessage = (state) => state.auth.message;
// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
