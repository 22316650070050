import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
//import icon
import iconEdit from 'src/assets/icons/ic_edit.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';
import iconDeleteBox from 'src/assets/icons/ic_delete_box.svg';
import iconCheckBox from 'src/assets/icons/ic_check_box.svg';

//features
import { selectSubServiceShowAdd, subServiceActions } from 'src/features/superAdmin/subService/slice';
import { digLogAction } from 'src/features/digLog/slice';

//import component
import CreateSubService from '../../../create';

//utils
import { contentDialog } from 'src/utils/contentDialog';

//import image
import User from 'src/assets/images/img_logo_clean_window.png';

//Scss
import styles from '../styles.module.scss';

const cx = classNames.bind(styles);

const TableItem = (props) => {
  const dispatch = useDispatch();

  const addShow = useSelector(selectSubServiceShowAdd);
  const [showSubService, setShowSubService] = useState(false);
  const [listSubService, setListSubService] = useState();

  const {
    _id,
    serviceGroup,
    name,
    typeServices,
    logo,
    isActive,
    isOther,
    lOther,
    note,
    emptySlot1,
    emptySlot2,
    emptySlot3,
    emptySlot4,
    emptySlot5,
  } = props;
  console.log(`[TableItem] props -> ${JSON.stringify(props, null, 2)}`);

  // TODO: @son-tran - update typeService
  // const price = typeServices.find((service) => service.name === 'OTHER');
  // const inside = typeServices.find((service) => service.name === 'INSIDE');
  // const outside = typeServices.find((service) => service.name === 'OUTSIDE');
  // const both = typeServices.find((service) => service.name === 'BOTH');

  const editSubService = () => {
    setListSubService({
      name: name,
      price: typeServices[0]?.price,
      id_price: typeServices[0]?._id,
      inside: typeServices[0]?.price,
      id_inside: typeServices[0]?._id,
      outside: typeServices[1]?.price,
      id_outside: typeServices[1]?._id,
      both: typeServices[2]?.price,
      id_both: typeServices[2]?._id,
      id: _id,
      isActive: isActive,
      isOther: isOther,
      logo: logo,
      serviceGroup: serviceGroup,
      note: note,
      emptySlot1: emptySlot1,
      emptySlot2: emptySlot2,
      emptySlot3: emptySlot3,
      emptySlot4: emptySlot4,
      emptySlot5: emptySlot5,
    });
    setShowSubService(true);
  };

  const deleteSubService = () => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE_CONFIRM.CONFIRM(name),
        function: () => {
          dispatch(subServiceActions.deleteSubService(_id));
        },
      })
    );
  };

  useEffect(() => {
    if (addShow === true) {
      setShowSubService(false);
    }
  }, [addShow]);

  const userLang = navigator.language || navigator.userLanguage;
  // alert('The language is: ' + userLang);

  useEffect(() => {
    console.log('The language is: ' + userLang);
  }, [userLang]);

  const str = '1.100.00.23';
  // const num = str.split('.').join(',');

  return (
    <>
      <div id='itItemSubService' className={lOther ? cx('itTableItemlOther') : cx('itTableItem')}>
        <div className={cx('itTbServiceName')}>
          <div className={cx('itTbServiceImg')}>
            <img
              src={logo || User}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = User;
              }}
              alt=''
              className={cx('img')}
            />
          </div>

          <div className={cx('nameService')}>{name || '0'}</div>
        </div>

        <div className={cx('itTbService')}>
          {typeServices[0]?.price == null || typeServices.length > 1 ? '-' : typeServices[0]?.price}
        </div>
        <p className={cx('itTbService')}>
          {typeServices[0]?.price == null || typeServices.length < 3 ? '-' : typeServices[0]?.price}
        </p>
        {lOther ? null : (
          <div className={cx('itTbService')}>
            {typeServices[1]?.price == null || typeServices.length < 3 ? '-' : typeServices[1]?.price}
          </div>
        )}
        <div className={cx('itTbService')}>
          {typeServices[2]?.price == null || typeServices.length < 3 ? '-' : typeServices[2]?.price}
        </div>
        {lOther ? null : (
          <div className={cx('itActive')}>
            {isActive ? (
              <div className={cx('itBorderIconCheck')}>
                <img src={iconCheckBox} className={cx('itTbCheckboxCheck')} alt='icon' />
              </div>
            ) : (
              <div className={cx('itBorderIconDelete')}>
                <img src={iconDeleteBox} className={cx('itTbCheckbox')} alt='icon' />
              </div>
            )}
          </div>
        )}

        <div className={cx('itItemActions')}>
          <div className={cx('itBtnGroupEdit')} onClick={editSubService}>
            <img src={iconEdit} alt='editIcon' className={cx('itButtonEdit')} />
          </div>
          <div className={cx('itBtnGroupDelete')} onClick={deleteSubService}>
            <img src={iconDelete} alt='trashIcon' className={cx('itButtonDelete')} />
          </div>
        </div>
      </div>
      <>
        <CreateSubService
          showSubService={showSubService}
          setShowSidebar={setShowSubService}
          title={true}
          listEdit={listSubService}
        />
      </>
    </>
  );
};

export default TableItem;
