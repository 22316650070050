// Library
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { TextBox } from 'devextreme-react/text-box';
import { Validator } from 'devextreme-react';
import { EmailRule } from 'devextreme-react/data-grid';
import { RequiredRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { Link, useNavigate } from 'react-router-dom';

// Components

// Features
import { authActions, selectAuthMessage, selectAuthStatus } from 'src/features/auth/authSlice';
import { digLogAction } from 'src/features/digLog/slice';
// Utils
import { exactRouter } from 'src/utils/routers';
import { redirectUrl } from 'src/utils/constants';
import { contentDialog } from 'src/utils/contentDialog';
import { handleErrorMessages } from 'src/utils/help';
// Icons

// Images

// Scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const message = useSelector(selectAuthMessage);
  const isSuccess = useSelector(selectAuthStatus);
  const [email, setEmail] = useState('');

  /**
   * Function Handle
   */
  const handleEmailChange = (e) => {
    setEmail(e.value);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();

    const body = {
      email: email,
      redirectUrl: redirectUrl,
    };
    if (isSuccess === null) {
      dispatch(authActions.forgotPassword(body));
    }
  };

  /**
   * UseEffect Block
   */
  useEffect(() => {
    if (isSuccess === true) {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.FORGOT_PASSWORD.SUCCESS,
          function: () => {
            if (email) {
              navigate(`${exactRouter.checkMail}`, {
                state: {
                  email: email,
                  redirectUrl: redirectUrl,
                },
              });
            }
            dispatch(authActions.resetStatus());
          },
        })
      );
    } else if (isSuccess === false) {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.FORGOT_PASSWORD.NONE,
          function: () => {
            dispatch(authActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, email, isSuccess, message, navigate]);

  return (
    <div className={cx('forgotPassword')} id='forgotPassword'>
      <div className={cx('fgTitle')}>
        <div className={cx('fgText')}>Forgot Password</div>
        <div className={cx('fgDesc')}>
          Enter your registered email below to <br /> receive password reset instruction.
        </div>
      </div>

      <form action='POST' className={cx('fgFromForgot')} onSubmit={handleForgotPassword}>
        <div className={cx('fgField')}>
          <p className={cx('fgRequired')}>Email*</p>
          <TextBox showClearButton={true} className={cx('fgInput')} value={email} onValueChanged={handleEmailChange}>
            <Validator>
              <RequiredRule message='Please fill in a valid email address' />
              <EmailRule message='Please fill in a valid email address' />
            </Validator>
          </TextBox>
        </div>

        <Button
          text='Send'
          activeStateEnabled={false}
          focusStateEnabled={false}
          type='success'
          className={cx('fgButton')}
          useSubmitBehavior={true}
        />
        <div className={cx('fgLogin')}>
          <Link to={`${exactRouter.auth}`} className={cx('fgLoginLink')}>
            Log in
          </Link>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
