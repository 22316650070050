import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
//import icon
import iconEdit from 'src/assets/icons/ic_edit.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';
import iconDeleteBox from 'src/assets/icons/ic_delete_box.svg';
import iconCheckBox from 'src/assets/icons/ic_check_box.svg';

//features
import { serviceActions, selectServiceShowAdd } from 'src/features/superAdmin/service/slice';
import { digLogAction } from 'src/features/digLog/slice';

//import component
import CreateService from 'src/pages/superAdmin/service/create';

//utils
import { contentDialog } from 'src/utils/contentDialog';

//import image
import User from 'src/assets/images/img_logo_clean_window.png';

//Scss
import styles from '../styles.module.scss';

const cx = classNames.bind(styles);

const TableItem = (props) => {
  const dispatch = useDispatch();

  const addShow = useSelector(selectServiceShowAdd);
  const [showService, setShowService] = useState(false);
  const [listService, setListService] = useState();

  const { _id, name, img, lOther, note, emptySlot1, emptySlot2, emptySlot3, emptySlot4, emptySlot5 } = props;
  // console.log(`[TableItem] props -> ${JSON.stringify(props, null, 2)}`);

  // TODO: @son-tran - update typeService
  // const price = typeServices.find((service) => service.name === 'OTHER');
  // const inside = typeServices.find((service) => service.name === 'INSIDE');
  // const outside = typeServices.find((service) => service.name === 'OUTSIDE');
  // const both = typeServices.find((service) => service.name === 'BOTH');

  const editService = () => {
    setListService({
      name: name,
      id: _id,
      img: img,
      note: note,
      emptySlot1: emptySlot1,
      emptySlot2: emptySlot2,
      emptySlot3: emptySlot3,
      emptySlot4: emptySlot4,
      emptySlot5: emptySlot5,
    });
    setShowService(true);
  };

  const deleteService = () => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE_CONFIRM.CONFIRM(name),
        function: () => {
          dispatch(serviceActions.deleteService(_id));
        },
      })
    );
  };

  useEffect(() => {
    if (addShow === true) {
      setShowService(false);
    }
  }, [addShow]);

  const userLang = navigator.language || navigator.userLanguage;
  // alert('The language is: ' + userLang);

  useEffect(() => {
    console.log('The language is: ' + userLang);
  }, [userLang]);

  const str = '1.100.00.23';
  // const num = str.split('.').join(',');

  return (
    <>
      <div id='itItemService' className={lOther ? cx('itTableItemlOther') : cx('itTableItem')}>
        <div className={cx('itTbServiceName')}>
          <div className={cx('itTbServiceImg')}>
            <img
              src={img || User}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = User;
              }}
              alt=''
              className={cx('img')}
            />
          </div>
          <div className={cx('nameService')}>{name || '0'}</div>
        </div>

        <div className={cx('itItemActions')}>
          <div className={cx('itBtnGroupEdit')} onClick={editService}>
            <img src={iconEdit} alt='editIcon' className={cx('itButtonEdit')} />
          </div>
          <div className={cx('itBtnGroupDelete')} onClick={deleteService}>
            <img src={iconDelete} alt='trashIcon' className={cx('itButtonDelete')} />
          </div>
        </div>
      </div>
      <>
        <CreateService showService={showService} setShowSidebar={setShowService} title={true} listEdit={listService} />
      </>
    </>
  );
};

export default TableItem;
