import React from 'react';
import classNames from 'classnames/bind';

//scss
import styles from './styles.module.scss';
const cx = classNames.bind(styles);

const TableHeaderRequest = () => {
  return (
    <div id='hhHederRequest' className={cx('hhHederRequest')}>
      <p className={cx('hhHeaderName')}>Household ID</p>
      <p className={cx('hhHeaderName')}>Household Name</p>
      <p className={cx('hhHeaderName')}>Date, Time</p>
      <p className={cx('hhHeaderName')}>Status</p>
      <p className={cx('hhHeaderName')}>Actions</p>
      <p></p>
    </div>
  );
};

export default TableHeaderRequest;
