import classNames from 'classnames/bind';
import React, { useState } from 'react';

//import utils
import { capitalizeFirstLetter } from 'src/utils/help';

//import icon
import iconEdit from 'src/assets/icons/ic_edit.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';

//import image
import imgAvatar from 'src/assets/images/img_avatar.jpg';

//import scss
import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { digLogAction } from 'src/features/digLog/slice';
import { houseActions } from 'src/features/superAdmin/household/slice';
import { contentDialog } from 'src/utils/contentDialog';

const cx = classNames.bind(styles);

const TableCardMob = ({ household, setShowHouse, showHouse, setListEdit }) => {
  const dispatch = useDispatch();
  const { _id, name, houseSize, email, householdId, phoneNumber, status } = household;

  const householdStatus = {
    CONNECTED: 'connectedStatus',
    NOT_CONNECT: 'notConnectStatus',
    DISCONNECT: 'disconnectStatus',
  };

  const editHouser = () => {
    setListEdit(household);
    setShowHouse(true);
  };

  const deleteHousehold = () => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE.CLICK,
        function: () => {
          dispatch(houseActions.deleteHousehold({ _id: _id, isActive: false }));
        },
      })
    );
  };

  return (
    <div id='itHhHdHeaderItemMob' className={cx('itHdTableBlock')}>
      <div className={cx('itHhHdItemHouseHold')}>
        <div className={cx('itHhHdBtnGroupEdit')}>
          <img src={iconEdit} alt='editIcon' className={cx('itHdButtonEdit')} onClick={editHouser} />
        </div>
        <div className={cx('itHhHdBtnGroupDelete')}>
          <img src={iconDelete} alt='trashIcon' className={cx('itHhHdButtonDelete')} onClick={deleteHousehold} />
        </div>
      </div>
      {/* <div className={cx('itHhHdHouseHoldImg')}>
        <img
          src={household.logo || imgAvatar}
          onError={(err) => {
            err.target.url = imgAvatar;
          }}
          className={cx('itHhHdImg')}
          alt='img'
        />
      </div> */}
      <div className={cx('itHhHdTableItem')}>
        <p>Household ID </p>
        <p className={cx('itHhItemName')}>{householdId || ' N/A'}</p>
      </div>
      <div className={cx('itHhHdTableItem')}>
        <p className={cx('nameHousehold')}>Household Name </p>
        <p className={cx('itHhItemName')}>{`${name.firstName} ${name.lastName}` || ' N/A'}</p>
      </div>
      <div className={cx('itHhHdTableItem')}>
        <p>House Size </p>
        <p className={cx('itHhItemName')}>{capitalizeFirstLetter(houseSize) || ' N/A'}</p>
      </div>
      <div className={cx('itHhHdTableItem')}>
        <p>Phone Number </p>
        <p className={cx('itHhItemName')}>{phoneNumber.phone || ' N/A'}</p>
      </div>
      <div className={cx('itHhHdTableItem')}>
        <p>Status</p>
        <p className={cx(`${householdStatus[status]}`)}></p>
      </div>
    </div>
  );
};

export default TableCardMob;
