import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

// Slice
import {
  combineServiceActions,
  selectCombineServiceList,
  selectCombineServiceTotal,
  selectCombineServicePagination,
} from 'src/features/superAdmin/combineService/slice';

// component
import TableHeaderCombineService from './table/tableHeader/TableHeader';
import PagiNation from 'src/components/common/pagination/Pagination';
import NoDataAvailable from 'src/components/common/noDataAvailable/NoDataAvaliable';
import TableItemCombineService from './table/tableItem/TableItem';

// scss
import styles from './styles.module.scss';

// Utils
import { exactRouter } from 'src/utils/routers';

const ListCombineService = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  // Util State
  const [isClicked, setIsClicked] = useState(null);

  // Selector
  const total = useSelector(selectCombineServiceTotal);
  const pagination = useSelector(selectCombineServicePagination);
  const listCombineService = useSelector(selectCombineServiceList);

  const handleAddNewService = () => {
    navigate(`${exactRouter.superAdmin.createCombineService}`);
  };

  /**
   * Get data
   */

  useEffect(() => {
    dispatch(combineServiceActions.resetState());
  }, []);

  useEffect(() => {
    if (listCombineService.length <= pagination.total) {
      dispatch(combineServiceActions.getAllCombineService(pagination));
    }

    //close accordion when pagination
    setIsClicked(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pagination.page]);

  /**
   * Handle pagination
   * @param {*} filter new filter when switch page
   */
  const handlePageChange = (pagination) => {
    dispatch(combineServiceActions.setPagination({ pagination }));
  };

  const handleToggleAccordion = (index) => {
    if (index === isClicked) {
      setIsClicked(null);
      return;
    }

    setIsClicked(index);
  };

  return (
    <div className={cx('cbWrapper')}>
      {/* Body title */}
      <div className={cx('lsTitle')}>
        <p className={cx('cbTitleNameService')}>Combines: {total}</p>
        <button className={cx('cbBtnAddCombineService')} onClick={handleAddNewService}>
          <p className={cx('cbPlus')}>+</p> <p>New Combine</p>
        </button>
      </div>
      <div className={cx('cbHeader')}>
        {/* Body content */}
        <div className={cx('lCardDesktop')}>
          <TableHeaderCombineService />
          <div className={cx('cbListItem')}>
            {total > 0 ? (
              listCombineService.map((combineService, index) => (
                <TableItemCombineService
                  combineService={combineService}
                  key={index}
                  index={index}
                  isClicked={isClicked}
                  handleToggleAccordion={handleToggleAccordion}
                />
              ))
            ) : (
              <NoDataAvailable />
            )}
          </div>
        </div>
        {/* Content pagination */}
        <div className={cx('cbPagination')}>
          {total > 0 ? (
            <PagiNation filter={pagination} total={pagination.total} handlePageChange={handlePageChange} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ListCombineService;
