import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  isSuccess: false,
  listRequests: [],
  total: null,
  paginationTotal: 0,
  filterEntry: {
    page: 1,
    limit: 10,
  },
  bookedCalendarList: [],
  isShowMessage: null,
  typeAction: null,
};

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    getAllRequest(state, action) {
      state.loading = true;
      state.isSuccess = null;
    },

    getAllRequestSuccess(state, action) {
      console.log(`[Request<Saga>] getAllRequestSuccess -> ${JSON.stringify(action.payload, null, 2)}`);

      state.loading = false;
      state.listRequests = action.payload.data.requests;
      state.total = action.payload.data.total;
      state.paginationTotal = action.payload.data.pagination.total;
    },

    getAllRequestFail(state, action) {
      state.loading = false;
    },

    filterRequest(state, action) {
      console.log(`[Request<Saga>] filterRequest -> ${JSON.stringify(action.payload, null, 2)}`);
      state.filterEntry = action.payload;
    },

    // Update request
    updateRequest(state, action) {
      state.loading = true;
    },
    updateRequestSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.typeAction = 'UPDATE';
      state.isShowMessage = true;
    },
    updateRequestFail(state, action) {
      state.loading = false;
      state.isSuccess = false;
      state.typeAction = '';
      state.isShowMessage = false;
    },

    // Get booked calendar
    getBookedCalendar(state, action) {},
    getBookedCalendarSuccess(state, action) {
      state.bookedCalendarList = action.payload;
    },
    getBookedCalendarFailed(state) {},

    // Delete request
    deleteRequest(state, action) {
      state.loading = true;
    },
    deleteRequestSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.isShowMessage = true;
      state.typeAction = 'DELETE';
    },
    deleteRequestFail(state, action) {
      state.loading = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },
    resetState: () => initialState,
    resetStatus(state) {
      state.typeAction = '';
    },
  },
});

export const requestActions = requestSlice.actions;

export const selectAllRequest = (state) => state.request.listRequests;
export const selectLoadingRequest = (state) => state.request.loading;
export const selectTotalRequest = (state) => state.request.total;
export const selectFilterRequest = (state) => state.request.filterEntry;
export const selectIsSuccessRequest = (state) => state.request.isSuccess;
export const selectPaginationTotal = (state) => state.request.paginationTotal;
export const selectBookedCalendarList = (state) => state.request.bookedCalendarList;
export const selectIsShowMessage = (state) => state.request.isShowMessage;
export const selectTypeAction = (state) => state.request.typeAction;

export const requestReducer = requestSlice.reducer;
export default requestReducer;
