const { requestUrl } = require('src/utils/constants');
const { default: axiosClient } = require('../axiosClient');

const requestApi = {
  getAllRequest(params) {
    const url = `${requestUrl.getAllRequest}`;
    return axiosClient.get(url, { params });
  },

  updateRequest(params) {
    const url = `${requestUrl.basic}/${params._id}`;
    return axiosClient.put(url, params.body);
  },

  getBookedCalendar(params) {
    const url = `${requestUrl.basic}/${requestUrl.calendarAvailable}?date=${params.date}&timezone=${params.timezone}`;
    return axiosClient.get(url);
  },

  deleteRequest(params) {
    const url = `${requestUrl.getAllRequest}/${params._id}`;
    return axiosClient.delete(url, params);
  },
};

export default requestApi;
